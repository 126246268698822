
body {
  background: $body-bg;
  height: 100%;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.limitTextHeight {
  height: 44px;
  overflow: hidden;
}
.plus-size{
  color: #d94b2a;
  cursor: pointer;
}
.topstirp{
  // background: #d94b2a;
font-size: 12px;
font-weight: 400;
color: #fff;
z-index: 9999999999;
text-align: center;
left: 0;
top: 0;
right: 0;
// position: fixed;
}
.topstirp_seeupdates{
  // background: #d94b2a;
font-size: 10px;

font-weight: 400;
color: #fff;
text-align: center;

z-index: 99;

}
.topstirp_seeupdates  p{
  margin: 0;
  padding: 3px 0;
  font-family: 'Work Sans Light';
  font-weight:600;
  letter-spacing:0.02em;
  font-style:normal;
}

.topstirp  p{
  margin: 0;
  padding: 5px 15px;
  font-family: 'Work Sans regular';
  font-weight: 500;
  letter-spacing: 0;
  font-style: normal;
  font-size: 14px;
  background: #44BCB4;
}
.container {
  width: 100%;
  max-width: 1324px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}
.pl-25 {
  padding-left: 25px;
}
.channelSlugText{
  text-align: center;
  color: #2ddbd1;
  
}
header {
  width: 100%;
  text-align: left;
  // padding-left: 75px;
  padding-left: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 19px 0 17px;
display: flex;
  head {
    display: inline-block;
    position: relative;
    z-index: 11;
    // padding: 0px 43px 0px 246px;
    font-weight: normal;
font-size: 14px;
align-self: center;
font-family: lato;
color: #828282;    }
  
  img{
    height: 60px;
    width: 160px;
    object-fit: scale-down;
  }
}
header {
  width: 100%;
  text-align: left;
  padding-left: 27px;
padding-right:27px;
  margin: 19px 0 17px;

  a {
    display: inline-block;
    position: relative;
    z-index: 11;
    padding: 0px 43px 0px 246px;
    font-weight: normal;
font-size: 14px;
font-family: lato;
color: #828282;    }
  
  // img{
  //   min-height: 60px;
  //   width: 100%;
  //   max-width: 100%;
  // }
}
.error-message{
  font-size: 10px;
}
.row {
  // height: calc(100vh - 120px);
  // height: 650px;
  width: 100%;
  // max-height: calc(100vh - 157px);
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 5px;
  max-height: calc(100vh - 80px);
}
.panel-left {
  width: 32.5%;
  background: $white;
  padding: 24px 0px 15px 23px;
  border: 1px solid #E7E7E7;
  @include border-radius(15px);
  position: relative;
  // min-height: 515px;
  // max-height: 600px;
  
  scrollbar-width: thin;
  scrollbar-color: #999999 #ffffff;
  max-height: 759px;

  // &::-webkit-scrollbar {
  //   width: 6px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #999999;
  // }
  // &::-webkit-scrollbar-track {
  //   background: transparent;
  // }

  &.loading:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ecebeb;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 1.2s linear infinite;
    z-index: 999;
  }

  h2.heading{
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .heading {
    color: $darkGray;
    font-size: 20px;
    font-family: $lato-font;
    margin-bottom: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.location-icon {
      justify-content: flex-start;
      .fa {
        margin-right: 10px;
        font-size: 25px;
      }
    }
   

    span {
      font-size: 14px;
      font-family: $montserrat-font;
      font-weight: 500;
      color: $grey;
      background: $white;
      padding: 2px 4px;
      @include border-radius(3px);
      @include box-shadow(1px, 1px, 2px, 1px, $shadow-color, 0.4);
    }
  }

  .purshase-details {
    color: #828282;
    font-size: 13px;
    line-height: 168%;
    margin-bottom: 34px;
    padding-right: 2px;
    letter-spacing: 2px;
    font-weight: 600;

    span {
      // color: $darkGray;
      color: #333;
    }
  }

  .purshase-details1 {
    color: #4F4F4F;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 5px;
    padding-right: 2px;
    letter-spacing: 0.02em;
    font-family: 'Work Sans Medium';
    font-weight: 500;
    font-style: normal;

    strong {
      color: $darkGray;
    }
  }

  .update-contact-list {
    margin-bottom: 34px;
    padding-left: 40px;
    position: relative;

    li {
      font-family: $lato-font;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      color: #808080;
      letter-spacing: 1px;

      .fa {
        font-size: 18px;
        margin-right: 15px;
      }

      &.call-icon {
        // background: url(assets/images/call-icon.png) no-repeat left center;
      }

      &.email-icon {
        // background: url(assets/images/email-icon.png) no-repeat left center;
      }

      a {
        color: #808080;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .ordered-items {
    
    width:100%;
    max-height: 230px;
    // min-height: 230px;
   padding-right: 5px;
    overflow-y: auto;
   

    &::-webkit-scrollbar {
      width: 12px;
  
      border-radius: 50px;
      background-color: #f6f6f6;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      width: 70%;
      padding:5px;
    }

    &::-webkit-scrollbar-thumb {
      background:#C8C8C8;
      border-radius: 50px;
      border: 3px solid #f6f6f6;
      width: 70%;
    }

    &::-webkit-scrollbar-thumb:hover {
     // background: $grey;
    }

    .item-list {



      display: flex;
      height: 90px;
      border: 1px solid #E7E7E7;
      background-color: #fff;
      border-radius: 8px;
      align-items: center;
      box-sizing: border-box;
      // @include border-radius(4px);
      margin-bottom: 8px;
      position: relative;
 padding-left: 8px;
 padding-right: 8px;
      img {
        height: 70px;
        width: auto;
      }

     

      &:hover {
        cursor: pointer;
        background-color: #fcfcfc!important;
      }
      &:after {
        content: "";
        width: 25px;
        height: 25px;
        background: url(https://ik.imagekit.io/2gwij97w0o/POE/right-arrow.svg) no-repeat center;
        @include border-radius(100%);
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      &.active {
        border: 1px solid transparent !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        border-radius: 8px;
        background-color: #F6F6F6!important;

       

        &:hover {
          .item-name {
            color: $light-black;
            text-decoration: inherit;
          }
        }
      }
    }

    .item-info {
      margin-left: 10px;
    }
    

    .item-name {
      color: #333;
      font-size: 15px;
      line-height: 16px;
      font-family: 'Work Sans medium';
      font-weight: 500;
      font-style: normal;
      width: 200px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      margin-bottom: 3px;
    }

    .item-status {
      font-size: 12px;
      font-family: 'Work Sans Light';
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 12px;
      letter-spacing: 1px;


      &.cancelled {
        color: $red;
      }

      &.transit {
        color: $status-orange;
      }
    }
  }
}

.panel-right {
  margin-left: 25px;
  width: calc(80% - 25px);
  background: $white;
  position: relative;
  // padding: 45px 36px;
  padding: 0;
  @include border-radius(15px);
  // @include box-shadow(0, 1px, 3px, 1px, $shadow-color, 0.14);
  border: 1px solid #E7E7E7;
  display: flex;
  position: relative;

  overflow: hidden;
  &.loading:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-color: #ecebeb;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
    -webkit-animation: progress-bar-stripes 2s linear infinite;
    -o-animation: progress-bar-stripes 2s linear infinite;
    animation: progress-bar-stripes 1.2s linear infinite;
    z-index: 999;
  }

  &>form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .scroll-content {
   
    overflow-y: auto;
    padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 13px;
      padding-left: 2px; padding-right: 2px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c8c8c8;
      border-radius: 20px;
      
      border: 3px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-track {
      background: #f6f6f6;
    }
    
  }

  h3 {
    color: $shadow-color;
    font-family: $lato-font;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 22px;
  }

  h5 {
    color: #4f4f4f;
    font-family: 'Work Sans Regular';
    font-size: 17px;
    line-height: 28px;
    letter-spacing:normal;
    margin-bottom: 5px;
    font-weight:600;
    font-style: normal;
    display: flex;
    width: 100%;
    &.pd-25 {
      padding-top: 32px;
    }
  }
  .heading-h5{
    margin-bottom: 18px;
  }
  .ordered-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    // overflow-y: scroll;
    // margin-bottom: 15px;
    margin-bottom: 0px;
    // overflow-y: auto;
    &::-webkit-scrollbar {
      width: 13px;
      padding-left: 2px; padding-right: 2px;
      border-radius: 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: #c8c8c8;
      border-radius: 20px;
      
      border: 3px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-track {
      background: #f6f6f6;
    }
    .item-list {
      display: flex;
     
      // margin-bottom: 90px;



      figure {
        width: 100%;
        max-width: 106px;
        max-height: 148px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .item-list1 {
      // display: flex;
      // @include border-radius(4px);
      // // margin-bottom: 90px;
      // margin-top: 176px;




      display: flex;
    // border-radius: 4px;
    margin-top: 130px;
    background: #FFFFFF;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    /* border-radius: 4px 4px 0px 0px; */
    width: 50%;


      figure {
        width: 100%;
        max-width: 106px;
        max-height: 148px;

        img {
          // width: 100%;
          // max-width: 100%;
          width: 100%;
          max-width: 84%;
          border-radius: 4px;
          margin: 10px;
        }
      }
    }

    .item-info {
      // margin-left: 30px;
      // display: flex;
      // flex-direction: column;
      // justify-content: space-around;
      margin-left: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 45px;
    }

    .item-name {
      color: $light-black;
      // font-size: 19px;
      // font-size: 10px;
      // line-height: 23px;
      // font-family: $lato-font;
      // letter-spacing: 1px;
      // margin-bottom: 12px;
      font-size: 19px;
      line-height: 12px;
      font-family: "Lato", sans-serif;
      
      margin-bottom: 10px;
      a {
        font-family: 'Work Sans Medium';
        font-size: 16px;
        line-height: 145%;
        font-weight: normal;
        font-style: normal;
        color: #828282 !important;
      }
    }

    .item-gender-info,
    .item-color-size {color: #808080;
      font-family: $lato-font;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px;
    }

    .item-price {
      color: $light-black;
      font-family: $lato-font;
      // font-size: 14px;
      // line-height: 20px;
      // font-weight: 300;
      // letter-spacing: 1px;
      // margin-bottom: 30px;
 
      font-size: 10px;
      line-height: 20px;
      font-weight: 0;
      letter-spacing: 1px;
      margin-bottom: 126px;
    }

    .item-status {
      font-size: 14px;
      font-family: $lato-font;
      text-transform: uppercase;

      &.cancelled {
        color: $red;
      }
    }

    .order-process {
      width: 94%;
      display: flex;
      position: relative;

      &:before {
        position: absolute;
        bottom: 0;
        left: 34px;
        right: 34px;
        
        height: 1.6px;
        content: "";
        border-top: 1px dashed #BBBBBB
      }

      &.cancelled {
        &:before {
          // background: $red;
        }
      }

      .tool-tip {
        position: absolute;
        top: -10px;
        left: 6px;
        @include translateX(-50%);
        background: $white;
        border: 1px solid $tool-tip-border;
        padding: 4px 6px;
        @include border-radius(2px);
        color: $grey;
        font-size: 11px;
        font-family: $lato-font;
        font-weight: 400;
        white-space: nowrap;
        display: none;
        // & ~ .tool-tip {
        //   display: none !important;
        // }
        &:not(:nth-last-of-type(1)){
            display: none !important;
          }

        &.arrow-bottom {
          top: 50px;
          left: 0;
          white-space: nowrap;
          @include translateX(0);
          font-size: 12px;

          &:after,
          &:before {
            bottom: 100%;
            left: 10px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: $white;
            border-width: 6px;
            margin-left: -6px;
          }

          &:before {
            border-color: rgba(238, 238, 238, 0);
            border-bottom-color: $tool-tip-border;
            border-width: 7px;
            margin-left: -7px;
          }
        }

        &.arrow-bottom-right {
          top: 50px;
          left: 46px;
          white-space: nowrap;
          @include translateX(-100%);
          font-size: 12px;

          &:after,
          &:before {
            bottom: 100%;
            right: 2px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
          }

          &:after {
            border-color: rgba(255, 255, 255, 0);
            border-bottom-color: $white;
            border-width: 6px;
            margin-left: -6px;
          }

          &:before {
            border-color: rgba(20, 5, 5, 0);
            border-bottom-color: $tool-tip-border;
            border-width: 7px;
            margin-left: -7px;
          }
        }
      }

      &.step-two {
        z-index: 99999;
        div {
          position: absolute;
          left: 20px;
              font-style: normal;
    font-weight: normal;
    font-family: 'Work Sans medium';
          font-size: 16px;
          color:#B7B7B7;
          transform: translateY(7px);
          // cursor: pointer;
          z-index: 99999999;
          line-height: 18px;
 

          &:before {
            position: absolute;
            top: -30px;
            
            content: "";
            width: 30px;
            height: 30px;
            background-color: #B7B7B7;
            background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/inactiveDelivery.svg');
            background-position:  center;
            background-repeat:  no-repeat;

            background-size: 18px 20px;
            border: 3px solid #DDDDDD;
            display: inline-block;
            @include border-radius(100%);
          }

          &.active {
            &:before {
              // background: #36D3C5;
              // width: 40px;
              // height: 40px;
              // top: 1px;
              // border: 5px solid #d7f6f3;
              width: 30px;
              height: 30px;
              
            }
            
          }

          &.bad {
            &:before {
              border: 5px solid #e69ba2 !important;
            }
          }
          &.red {
            &:before {
              background: red;
              z-index: 9999;
              border: 1px solid red;
            }
          }

          &:last-child {
            left: inherit;
            right: 20px;
            text-align: right;
&>p{
  color: #B7B7B7 ;
}

            &::before {
              // left: 0;
            }
          }
          &:hover {
            .tool-tip {
              display: block;
            }
          }
          &:focus {
            .tool-tip {
              display: block;
            }
          }
          &.active{
        
            color: #4f4f4f;

          }
        }

        &.step-four {
          div {
            // cursor: pointer;
            &.small {
              left: 48%;
             
              // top: -27px;

              &:before {
                position: absolute;
                
                left: 0%;
                content: "";
                width: 30px;
                height: 30px;
                background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/inactivetransit.svg');
                background-position: center;
                background-repeat:  no-repeat;
background-size: 65% 65%;

                display: inline-block;
                @include border-radius(100%);
              }

              &.active {
                &:before {
                  background-color: #ECB73F;
                  border-color:#F3DB71;
                  // border: 5px solid #d7f6f3;

                }
                &.red {
                  &:before {
                    background: #d64b2c;
                    border: 1px solid #d64b2c;
                    z-index: 9999;
                  }
                }
              }

              &.bad {
                &:before {
                  border: 5px solid #e69ba2 !important;
                }
              }

              &:nth-child(3) {
                left: 66.666666%;
              }

              .small-status {
                // position: absolute;
                // top: -8px;
                // left: 6px;
                // @include translateX(-50%);
                // background: $white;
                // border: 1px solid $tool-tip-border;
                // padding: 2px 6px;
                // @include border-radius(2px);
                display: block;
                color: #B7B7B7;
                font-size: 16px;
                font-style: normal;
                font-weight: normal;
                font-family: 'Work Sans medium';
                line-height: 18px;
                // padding-top: 20px;
                // display: none;
                // &:after,
                // &:before {
                //   top: 100%;
                //   left: 50%;
                //   border: solid transparent;
                //   content: " ";
                //   height: 0;
                //   width: 0;
                //   position: absolute;
                //   pointer-events: none;
                // }

                // &:after {
                //   border-color: rgba(255, 255, 255, 0);
                //   border-top-color: $white;
                //   border-width: 6px;
                //   margin-left: -6px;
                // }

                // &:before {
                //   border-color: rgba(238, 238, 238, 0);
                //   border-top-color: $tool-tip-border;
                //   border-width: 7px;
                //   margin-left: -7px;
                // }
              }
            }
          }

          // &.active-two {
          //   &:after {
          //     position: absolute;
          //     left: 0;
          //     width: 33.333333%;
          //     height: 2px;
          //     content: "";
          //     background: $green-success;
          //   }
          // }

          // &.active-three {
          //   &:after {
          //     position: absolute;
          //     left: 0;
          //     width: 66.666666%;
          //     height: 2px;
          //     content: "";
          //     background: $green-success;
          //   }
          // }

          // &.active-four {
          //   &:after {
          //     position: absolute;
          //     left: 0;
          //     width: 100%;
          //     height: 2px;
          //     content: "";
          //     background: $green-success;
          //   }
          // }
        }
      }
    }

    .status-message {
      font-family: $montserrat-font;
      font-size: 13px;
      line-height: 28px;
      color: $green-success;

      &.cancelled {
        color: $red;
        text-decoration: line-through;
      }
    }

    .action-container {
      width: 100%;
      display: flex;
      margin: 30px 0;
      align-items: flex-start;
      justify-content: flex-end;

      .status-note {
        width: 44.5%;
        padding: 15px;
        background: $light-orange;
        @include border-radius(4px);
        text-align: center;
        font-family: $lato-font;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        min-height: 130px;
        display: flex;
        align-items: center;
        position: relative;

        &:before {
          content: "";
          width: 91px;
          height: 24px;
          background: rgba($white, 0.7);
          border: 1px solid rgba($notes-sticker-border, 0.7);
          position: absolute;
          left: 50%;
          top: -12px;
          @include translateX(-50%);
        }
      }

      .btn-container {
        margin-left: 30px;
        width: calc(54.5% - 30px);
      }

      .default-btn {
        background: $defaull-btn-bg;
        border: 1px solid $defaull-btn-border;
        border-width: 1px 0 1px 0;
        color: $default-btn-color;
        font-family: $lato-font;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 15px 25px;
        display: flex;
        align-items: center;
        &.btn-confirm {
          padding-right: 5px;
          border-bottom: none;
        }

        &.need-help-btn {
          justify-content: space-between;
          .fa {
            font-size: 25px;
          }
        }

        &.return-btn {
          justify-content: space-between;
          .fa {
            font-size: 12px;
            color: #fff;
            background: #2d78ef;
            border-radius: 50%;
            padding: 5px;
          }
        }

        &.cancelled-btn {
          color: $red;
          justify-content: space-between;
          .fa {
            font-size: 25px;
          }
        }
        &.corrective-btn {
          color: #59b84d;
          justify-content: space-between;
          .fa {
            font-size: 25px;
          }
        }

        & + .default-btn {
          border-top: 0;
        }
      }
    }
  }
  .special-para{
    font-family: 'Work Sans medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #666666;
  }
  .btn-bottom {
    position: relative;
    background: $white;
    width: 100%;
    padding: 23px 0px;
    padding-left:25px;
    display: flex;
    border-top: 1px solid #f7f7f7;
    justify-content: flex-start;
  }

  .btn-continue {
    height: 32px;
    cursor: pointer;
    font-family: 'Work Sans semibold';
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 4px;
    line-height: 14px;
    text-transform: uppercase;
     font-style:normal;
    font-size: 12px;
    letter-spacing:1px;
    color: #fff;
    padding: 8px 0px;
    margin-left: 16px;
    background: #2DDBD1;
    width: 90px;
    text-align: center;
    -webkit-appearance: none;
    &:hover{
      box-shadow: 0px 4px 5px #e4e4e4;
    }
    &:disabled {
     
      pointer-events: none;
      background: #B7B7B7;
   
    }
 
  }

  .btn-cancel {
    cursor: pointer;
    border: 1px solid #828282;
    border-radius: 4px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #828282;
    padding: 8px 18px;
    text-transform: uppercase;
    height: 32px;
    background: none;
     font-family: 'Work Sans semibold';
    font-weight: 500;
    transition: all ease-in-out .2s;
    width: 90px;
    &:hover{
      // background: #f9f9f9;      
      box-shadow: 0 0 0 1px rgba(0, 0 ,0 , 0.1), 0 1px 3px rgba(0, 0, 0 , 0.1);
    }
  }

  .send-otp-btn {
    font-family: 'Work Sans medium';
    line-height: 14px;
    font-weight: 500;
    color: #2DDBD1;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    &:hover {
      color: #31bfb7;
    }
  }

  &.otp-right-panel {
    display: flex;
    flex-direction: column;
  }
  .otp-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}



.cancel-container{
  margin-top: 24px;
}

.form-group {
  width: 75%;
  label {
    color: $light-black;
    font-family: $montserrat-font;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 10px;

    & + span {
      color: $light-grey;
      font-family: $montserrat-font;
      font-size: 14px;
      line-height: 14px;
    }
  }

  select {
    @include appearance(none);
    &.form-control {
      background: url(assets/images/select-box-arrow.png) right center no-repeat;
    }
  }

  .form-control {
    width: 100%;
    color: $light-black;
    font-family: $lato-font;
    font-size: 13px;
    line-height: 22px;
    border: 0;
    border-bottom: 1px solid #D8D8D8;
  }
}

.otp-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  h2 {
   /* Bold/Strong */

font-family: 'Work Sans Light';
    font-style: normal;
font-weight:600;
font-size: 16px;
line-height: 19px;
/* identical to box height */

text-align: center;

/* Dark Gray */

color: #828282;
    margin-bottom: 12px;
  }

  h4 {
    color: $grey;
    font-family: $lato-font;
    font-size: 16px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 10px;
    text-transform: uppercase;
    display: none;
  }

  p {
    font-family: 'Work Sans regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #666;

    span {
      display: block;
      font-weight: 500;
      font-size: 14px;
    }

    strong{
      font-weight: 500;
      color: #333333;
      font-family: 'Work Sans medium';
    }
  }

  figure {
    margin: 0 0 25px 0;
  }

  .form-group {
    margin: 30px 0 25px;
    margin-bottom: 35px;
    width: 100%
  }

  .form-control {
    border: 1px solid $light-grey;
    text-align: center;
    letter-spacing: 1px;
    color: $grey;
    font-family: $montserrat-font;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 5px;
    @include border-radius(3px);

    &.error-control {
      border-color: $red;
      background: $error-bg;
    }
  }

  .error {
    color: #EB5757;
    font-family: 'Work Sans medium';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    position: absolute;
  }

  &.success-page {

    figure {
      margin: 0 0 25px 0;
    }
  }

  .request-number {
    font-family: $montserrat-font;
    font-size: 16px;
    line-height: 22px;
    color: $grey;
    margin-bottom: 40px;
  }

  .confirmation-message {
    /* Small */

font-family: 'Work Sans Light';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 145%;
/* or 15px */

text-align: center;
letter-spacing: 0.02em;

/* Dark Gray */

color: #828282;
    margin-bottom: 20px;
  }
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex: 0 0 50%;
  padding-right: 50px;
  position: relative;

  &.form-block {
    flex: 0 0 100%;
  }

  &.form-column-third {
    flex: 0 0 70%;
  }
}

.example-full-width {
  width: 70%;
}

.example-full-width-failed{
  width: 300px;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
 color: #4F4F4F!important;
  font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.02em;
}
.mat-form-field-appearance-legacy .mat-form-field-label.mat-empty {
  font-size: 12px;
  
}
.radio-placeholder {
  color: $light-grey;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 10px;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: $light-grey;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #2DDBD1;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:rgba(45, 219, 204, 0.5);
}

.mat-radio-label-content {
  margin-right: 20px;
  font-family: $montserrat-font;
  font-size: 13px;
  color: $grey;
}

.registered-address {
  .mat-radio-container{
display: none!important;
  }
  width: 338px;

  padding: 18px 10px;
  padding-bottom: 12px;
  font-size: 10px;
  background: #FDFDFD;
  border: 1px solid #F6F6F6;
border-radius: 5px;
  margin-bottom: 18px;
  margin-left: 3px;

  p {
    padding: 8px 0 0 8px;
    // color: $light-grey;
    color: #4F4F4F;
   font-family: 'Work Sans Light';
    font-size:14px;
    line-height: 160%;
    font-weight:bold;
    font-style:normal;
    span {
      display: block;
    }
  }

  .mat-radio-label-content {
    font-family: $montserrat-font;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
   
    color: $light-black;
   
  }
}

.add-address-btn {
  color:#2DDBD1;
  font-family: 'Work Sans Regular';
  font-size: 13px;
  line-height: 15px;
  font-weight: bolder;
  text-transform: uppercase;
  span {
    margin-right: 10px;
    font-weight: 700;
    display: none;
  }
}

.check-ifsc-link {
  color: $light-grey;
  font-family: $montserrat-font;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  text-decoration: underline;
  position: absolute;
  right: 15px;
  top: 17px;
}

.check-ifsc-loader {
  position: absolute;
  right: 14px;
  top: 7px;
}

/*===== Alert Message =======*/
app-web-alert {
  position: fixed;
  top: 35px;
  right: 15px;
  width: 100%;
  max-width: 350px;
  z-index: 99999998;
}

.notify {
  position: relative;
  right: 0;
  top: 15px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  opacity: 1;
  z-index: 99999999;
  border: 1px solid;

  &:last-child {
    margin-bottom: 0;
  }

  &.notify-default {
    background-color: #f0f0f0;
    border: 1px solid #e3e3e3;
  }

  &.notify-success {
    background-color: #77dd77;
    border-color: #63d863;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  &.notify-error {
    background-color: #d84941;
    border-color: #d43c34;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  &.notify-info {
    background-color: #89abd2;
    border-color: #779ecb;

    .notify-body {
      color: #ffffff;

      * {
        color: #ffffff;
      }
    }

    .notify-action-buttons {
      .notify-square-button {
        &.notify-close-button {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
        }
      }
    }
  }

  .notify-body {
    padding: 15px;
    font-size: 13px;
    min-height: 54px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;

    .notification-body-icon {
      float: left;
      width: 38px;
      height: 32px;
      vertical-align: top;
      display: inline-block;
      position: relative;
      text-align: center;
      font-size: 32px;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .notify-body-title {
      display: block;
      font-weight: 700;
      text-transform: capitalize;
    }

    .notify-main-content {
      width: -webkit-calc(100% - 38px);
      width: calc(100% - 38px);
      float: left;
      padding-left: 10px;

      * {
        font-size: 14px;
        line-height: 1.2;
      }
    }
  }

  .notify-action-buttons {
    width: 16px;
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 2px;

    .notify-square-button {
      background-position: center;
      background-size: 8px;
      background-repeat: no-repeat;
      display: block;
      height: 16px;
      width: 16px;

      &.notify-close-button {
        display: block;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGQ9Ik0xMC4xMTksOGw0LjU5Ny00LjU5N2MwLjU4Ni0wLjU4NSwwLjU4NS0xLjUzNCwwLTIuMTE5cy0xLjUzMy0wLjU4NS0yLjExOSwwTDgsNS44ODFMMy40MDMsMS4yODQNCgljLTAuNTg1LTAuNTg1LTEuNTM0LTAuNTg1LTIuMTE5LDBjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTlMNS44ODEsOGwtNC41OTgsNC41OThjLTAuNTg1LDAuNTg1LTAuNTg1LDEuNTMzLDAsMi4xMTkNCgljMC41ODUsMC41ODQsMS41MzMsMC41ODQsMi4xMTgsMEw4LDEwLjExOGw0LjU5OCw0LjU5OGMwLjU4NSwwLjU4NSwxLjUzMywwLjU4NSwyLjExOSwwYzAuNTg0LTAuNTg1LDAuNTg0LTEuNTMzLDAtMi4xMThMMTAuMTE5LDh6DQoJIi8+DQo8L3N2Zz4NCg==");
      }
    }
  }
}
/*Alert End Here*/

.loader-layover {
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  position: fixed;
  top: 0;
  left:0;
  right:0;
  bottom:0;
  z-index: 999999999999999999999999999999999999;
  display: flex;
  justify-content: center;
  // align-items: center;
  figure{
    width: 100vw;
    img {
      max-width: 100%;
      width:100%;
      display: block;
    }
  }
}

.left-panel-show {
  width: calc(100% + 40px);
  border-bottom: 2px solid $body-bg;
  margin-right: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  padding-bottom: 10px;
  padding-left: 20px;
  display: none;
  figure {
    width: 28px;
    height: 28px;
    background: $body-bg;
    @include border-radius(100%);
    cursor: pointer;
    text-align: center;
    line-height: 33px;
    display: inline-block;
  }
}

.left-panel-hide {
  width: calc(100% + 40px);
  border-bottom: 2px solid $body-bg;
  margin-right: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
  padding-bottom: 10px;
  padding-right: 20px;
  display: none;
  figure {
    width: 28px;
    height: 28px;
    background: $body-bg;
    @include border-radius(100%);
    cursor: pointer;
    text-align: center;
    line-height: 28px;
    margin-left: auto;

    img {
      width: 10px;
      height: 10px;
    }
  }
}

.mat-select-panel {
  scrollbar-width: thin;
  scrollbar-color: $light-grey $white;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $light-grey;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }
}

footer {
  position: absolute;
  top: 52px;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1324px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  a {
    border: 1px solid $footer-border;
    padding: 6px 10px;
    display: inline-block;
    color: $darkGray;
    @include border-radius(4px);
    background: $white;
    &:hover{
      background: #f7f7f7;
      transition: all ease-in-out .2s;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    }
  }

  img {
    vertical-align: middle;
    margin-right: 4px;
  }
}

// .email-link-container {
//   position: absolute;
//   right: 30px;
//   bottom: 20px;
//   a {
//     border: 1px solid $footer-border;
//     padding: 6px 10px;
//     display: inline-block;
//     color: $black;
//     @include border-radius(4px);
//     background: $white;
//   }
//   img {
//     vertical-align: middle;
//     margin-right: 8px;
//   }
// }

.status-blue-color {
  color: $blue;
}
.status-yellow-color {
  color: $status-orange;
}
.status-green-color {
  color: $green-success;
}
.status-red-color {
  color: $red;
}
.form-Loader {
  position: relative;
}
.form-Loader .loader-layover {
  position: absolute;
  top: 0;
  left: 0;
}

.btn-continue.btn-loader,
.btn-loader {
  width: 90px;
  height: 32px;
  
}

.otp-auth-container {
  width: 100%;
  max-width: 590px;
  background-color: #fff;
  margin: 0 auto;
  box-shadow: 3px -4px 6px rgba(1, 1, 1, 0.14);
  border-radius: 5px;
  padding: 50px 45px;
  margin-top: 45px;
  font-family: $montserrat-font;
  color: #333;
  line-height: 22px;
  .otp-auth-form-inner {
    padding-top: 8px;
  }
  h2 {
    text-align: center;
    font-size: 24px;
    line-height: 22px;
    color: #333;
    font-family: $microsoft-font;
    text-transform: uppercase;
    font-weight: 400;
  }
  .otp-form-container {
    padding-top: 34px;
  }
  .input-container {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #333;
    position: relative;
    font-family: $montserrat-font;
    input[type="text"],
    input[type="password"] {
      border: 0;
      border-bottom: 1px solid #dadada;
      height: 40px;
      padding: 5px;
      width: 100%;
      font-size: 18px;
      font-family: $montserrat-font;
      font-weight: 300;
      color: #333;
    }
    input:placeholder {
      color: #999999;
    }
    input.error {
      border-color: #f04444;
    }
    input[type="checkbox"] {
      width: 14px;
      height: 14px;
      padding: 0;
      margin: 0;
      position: absolute;
      opacity: 0;
      z-index: 99;
      cursor: pointer;
    }
    .checkmark {
      position: absolute;
      background: #fff;
      top: 2px;
      left: 0;
      border: 1px solid #e1e1e1;
      width: 15px;
      height: 15px;
      border-radius: 1px;
    }
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      left: 4px;
      top: 2px;
      width: 3px;
      height: 7px;
      border: solid #3099f1;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    input[type="checkbox"]:checked ~ .checkmark:after {
      display: block;
    }
    .checkmark ~ label {
      margin-left: 20px;
      font-size: 12px;
    }
  }

  .send-otp-form .input-container input[type="text"]:nth-child(1) {
    max-width: 45px;
    margin-right: 10px;
  }

  .form-message {
    padding-top: 15px;
    p {
      font-size: 13px;
      color: #999999;
      font-family: $montserrat-font;
      font-weight: 300;
      line-height: 15px;
    }
  }
  .btn-send-otp,
  .btn-auth {
    display: block;
    background-color: #f04444;
    color: #fff;
    width: 100%;
    border: 0;
    border-radius: 2px;
    padding: 14px;
    text-transform: uppercase;
    font-size: 18px;
    margin: 28px 0;
    font-family: $montserrat-font;
    font-weight: 600;
    transition: all 300ms ease-in;
    cursor: pointer;

    &:disabled {
      background-color: #ef8f8f;
    }
    &:focus {
      outline: 0;
      box-shadow: none;
    }
  }
  .otp-auth-form .input-container {
    justify-content: center;
    padding: 24px 0 15px;
    position: relative;
    width: 368px;
    margin: 0 auto;
    left: 30px;
    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 49px;
      background: #fff;
      top: 0;
      right: 0;
    }
    input[type="password"] {
      width: 368px;
      padding-left: 20px;
      letter-spacing: 44px;
      border: 0;
      font-size: 36px;
      background-image: linear-gradient(to left, #dadada 70%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 54px 1px;
      background-repeat: repeat-x;
      background-position-x: 42px;
      text-align: left;
      &.error {
        background-image: linear-gradient(to left, #f04444 70%, rgba(255, 255, 255, 0) 0%);
      }
    }
  }
  .change-mob-num {
    color: #f04444;
    text-decoration: underline;
  }
  .resend-otp-link {
    font-size: 13px;
    font-weight: 200;
    color: #0c98cd;
    text-decoration: underline;
    margin-top: 22px;
    display: inline-block;
  }
  .error-wrapper {
    padding-top: 9px;
    font-size: 13px;
    .error {
      color: #f04444;
    }
  }
}
.d-none {
  display: none !important;
}
.text-center {
  text-align: center;
}

.size-radio-btn {
  .mat-error {
    padding-top: 20px;
  }
  .radio-btn {
    position: relative;
    width: 58px;
    height: 58px;
    margin-right: 20px;
    cursor: pointer;
    &.selected {
      .mat-radio-container .mat-radio-outer-circle {
        border-color: #867373;
      }
      .mat-radio-label-content span:last-child {
        color: #2DDBD1;
      }
    }
    &:hover {
      .mat-radio-container .mat-radio-outer-circle {
        border-color: #2DDBD1;
        background: #fff;
      }
      .mat-radio-label-content span:last-child {
        color: #2DDBD1;
      }
    }
    .mat-radio-container {
      width: 58px;
      height: 58px;
    }
    .mat-radio-outer-circle {
      width: 58px;
      height: 58px;
      border-color: #808080;
      border-width: 2px;
    }
  }
  .mat-radio-button .mat-radio-ripple {
    position: absolute;
    left: auto;
    top: auto;
    height: 58px;
    width: 58px;
    z-index: 1;
    pointer-events: none;
}

  .mat-radio-label-content span {
    font-size: 16px;
    color: #343434;
    font-family: "lato", sans-serif;
    font-weight: 600;

    &:last-child {
      position: absolute;
      top: 19px;
      left: 0;
      width: 100%;
      text-align: center;
    }
  }
}

.size-radio-btn .radio-btn[disabled],
.radio-btn.radio-disabled {
  .mat-radio-outer-circle {
    border-color: #cbcbcb;
    cursor: pointer;
  }
  .mat-radio-label-content span {
    color: #cbcbcb;
  }
}
.size-radio-btn .radio-btn[disabled]:hover,
.size-radio-btn .radio-btn.radio-disabled:hover {
  .mat-radio-outer-circle {
    border-color: #cbcbcb;
  }

  .mat-radio-label-content span {
    color: #cbcbcb;
  }
}
.size-radio-btn .radio-btn.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #2DDBD1;
  }
  .mat-radio-inner-circle {
    display: none;
  }
  .mat-radio-label-content span {
    color: #2DDBD1;
  }

}


.prev-ordered-size {
  margin: 20px 0 5px;
  display: flex;
  width: 100%;
  
  h4 {
    font-size: 14px;
    color: #343434;
    font-family: 'lato';
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    font-weight: normal;
  }
  span.prev-size {
    margin-left: 5px;
    display: inline-block;
    color: #F30E0F;
    font-family: "lato", sans-serif;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.5px;
  }
}
.custom-label {
  font-size: 12px;
  
  // color: #828282;
}

.product-detail-mob {
  display: none;
  margin-bottom: 50px;
  .prod-img {
    margin-right: 20px;
    // padding-left: 30px;
    img {
      width: 100%;
      max-width: 106px;
      max-height: 148px;
      border-radius: 6px;
    }
  }
  .prod-info {
    h4 {
      font-size: 16px;
      color: #333333;
      font-family: "lato", sans-serif;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-family: $lato-font;
      margin-bottom: 0;
    }
  }
}

@keyframes progress-bar-stripes {
  from {
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(246, 246, 246, 1) 47%,
      rgba(237, 237, 237, 1) 100%
    );
  }
  to {
    background-image: linear-gradient(
      to right,
      rgba(237, 237, 237, 0.5) 0%,
      rgba(246, 246, 246, 0.5) 53%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
}
.pt-0 {
  padding-top: 0 !important;
}
.courier-details {
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 14px;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  padding-bottom: 15px;
}

.custom-radio-btn-group {
  color: #666666;
  font-size: 14px;
  line-height: 22px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
display: flex;
flex-direction: column;
  .custom-radio-btn {
margin-bottom: 20px;

    &.mat-radio-checked {
      .mat-radio-outer-circle {
        border-color: #2DDBD1 !important;
        border-width: 1px;
      }
      .mat-radio-inner-circle {
        background-color: #2DDBD1 !important;
      }
    }
    .mat-radio-outer-circle {
      border-color: #bdbdbd;
      border-width: 1px;
    }
    .mat-radio-inner-circle {
      background-color: #fff !important;
    
      transform: scale(0.6);
    }
    .mat-radio-label-content {
      color: #4F4F4F;
      font-size: 14px;
      white-space: initial;
      line-height: 22px;
      font-family: 'Work Sans medium';
      font-weight: 500;
      padding-left: 15px !important;
    }
  }
}
.mtn-6 {
  margin-top: -6px;
}
.later-date {
  margin-bottom: 20px;
  .mat-radio-container {
    height: 0;
    width: 0;
    opacity: 0;
  }
  .mat-radio-label-content {
    margin-right: 0;
   
  }
  .mat-radio-button {
    display: inline-block !important;
  }
  li {
    display: inline-block;
    margin-right: 10px;
    padding: 8px;
    /* Lightest Gray */

background: #F6F6F6;
/* Disabled Accent */
letter-spacing: 1px;
    font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: 600;
border: 1px solid  #B7B7B7;
box-sizing: border-box;
border-radius: 4px;

      color: #000;
      text-align: center;
      &.active {
        background: #2DDBD1;
          color: #fff;
          border-color: transparent;
      }
      &:hover{
        border-color: #2DDBD1;
      }
    }
  
  }


.failed-delivery {
  .scroll-content {
    padding-left: 0;
    padding-top: 0;
  }
  .failed-delivery-content {
    max-width: 95%;
    .courier-details {
      padding-left: 0;
    }
    h5 {
      margin-left: 0;
    }
    .mat-radio-button {
      display: block;
    }
    .custom-radio-btn-group .custom-radio-btn .mat-radio-label-content {
      white-space: normal;
    }
  }
}
#returnCancelReason{
      -webkit-transform: translate3d(0,0,0);
}

.buyer-info {
  // padding-left: 40px;
  position: relative;
  padding-left: 14px;
}
.buyer-detail h3 {
  font-size: 14px;
  line-height: 15px;
  letter-spacing:0.02em;
  color: #333;
  text-transform: uppercase;
  font-family: 'Work Sans Medium';
  font-weight:500;
  font-style:normal;
  margin-bottom: 8px;
}
span.location-icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.panel-left .heading.m-size {
  font-family: 'Work Sans Light';
  font-style: normal;
  font-weight:600;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #828282;
  margin-bottom: 12px;
}

ul.update-contact-list .refresh-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
ul.update-contact-list .refresh-icon img {
  object-fit: contain;
}
.buyer-detail .purshase-details{
  font-size: 11px;
}
.panel-left .ordered-items .item-color-size, 
.panel-left .ordered-items .item-price {
  color: #777777;
  letter-spacing: 0.02em;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 6px;
  font-family: 'Work Sans medium';
  font-style:normal;
  font-weight:normal;
}
.action-btn .cancelled-btn {
  background: rgba(253, 225, 226, 0.5);
  border: 1px solid #FA5C5D;
  border-radius: 4px;
  line-height: 17px;
  font-size: 14px;
  letter-spacing: 1px;
  color: #F30E0F;
  padding: 9px 14px;
  margin-right: 12px;
  &:hover{
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    background: #ffdadb;
  }
}
.action-btn .need-help-btn {
  border: 1px solid #CFC9C9;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: #333333;
  padding: 9px 14px;
  &:hover{
    background: #f7f7f7;
    transition: all ease-in-out .2s;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }
}
mat-form-field .mat-select-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid transparent;
  margin: 0 4px;
  width: 7px;
  height: 7px;
  border: 1px solid #808080;
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
}
.resend-count{
  color: #808080;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  font-family: 'Work Sans Light';
  span{
    color: #666;
    display: inline-block !important;
    font-family: 'Work Sans Medium';
  }
}
.mobile-otp-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.otp-section.success-page{
  text-align: center;
}
.account-holder-detail {
  width: 100%;
}
.account-holder-detail .text-right{
  // margin-right: 50px;
  // margin-bottom: 24px;
}
.account-holder-detail .form-column{
  margin-bottom: 9px;
}
.panel-right h5.padd-40{
  margin-bottom: 30px;
}
.panel-right-failed-del{
  h5{
    max-width:470px;
    line-height: 24px;
    margin-bottom:20px!important;
  }
}
span.no-tooltip {
  display: block;
  font-size: 13px;
  color: #777;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-style: normal;
  font-weight: normal;
  font-family: 'Work Sans Regular';
}
span.i-status {
  // position: relative;
  // display: inline-block;
  // background: #F2F2F2;
  // border-radius: 4px;
  // padding: 6px 15px;
  // font-size: 12px;
  // line-height: 14px;
  // color: #808080;
  // margin-top: 7px;
  // margin-bottom: 12px;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  background: #B7B7B7;
  border-radius: 2px;
  padding: 4px 6px;
    font-size: 9px;
  line-height: 5px;
  color: white;
 margin: 10px 0 10px 1px;
//  font-family: 'Work Sans medium';
 font-weight: bold;
}
span.oder-status-note {
  display: block;
  font-size: 14px;
  color: #808080;
  line-height: 148%;
  letter-spacing: 0.04em;
  max-width:295px;
  font-weight: normal;
  padding:5px 0px;
  font-family: 'Work Sans medium';
  
  a{
    color: #2DDBD1;
   
    font-weight: 600;
    position: relative;
    &::after{
      content: '';
    position: absolute;
    height: 1px;
    left: 0px;
    right: 0px;
    bottom: 2px;
    background-color: #2DDBD1;
    }
  }
}

.first-boxxx{
  margin-top: 10px !important;
  border: none;
  border-left: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
                                      
}
span.oder-status-note1 {
  display: block;
  font-size: 13px!important ;
  text-align: left!important;
  color: #777;
  line-height: 14px;
  background-color: #FDFDFD;
  max-width: 500px;
font-family: 'Work Sans Light';

  font-weight: normal;
  width: 299px;
  padding: 10px;
 
  // border-radius: 4px;

  font-style: normal;
  border: 1px solid #F6F6F6;

  letter-spacing: 0.02em;
  margin-top: -1px;
  span.checkbox img{
    // background: white;
    // border-radius: 50%;
    // width: 28px;
    // height: 28px;
    // border: 1px solid #f6fff9;
    // padding: 2px;
   }
   span.checkbox1 img{
    background: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    border: 1px solid red;
    padding: 2px;
   }
  

   span.cancelbutton{
    
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
    font-family: 'Work Sans Semibold';
    font-style:normal;
   
    letter-spacing: 0.02em;
    text-transform: uppercase;
    
    /* Accent */
    a{
      background: rgba(45, 219, 209, 0.1);
border: 1px solid #2DDBD1;
box-sizing: border-box;
border-radius: 4px;
width: auto;
height: 32px;
padding: 2px 13px 0;
align-items: center;
display: inline-flex;
line-height: 33px;
    }
    color: #2DDBD1;
//     a:hover{
// color: #295250;
//     }
   }
}
span.oder-status-note1.first-boxxx{  
  border-radius: 4px 4px 0px 0px;
  margin-top: 0px;
}

span.oder-status-note1:last-child{
  border-radius: 0px 0px 4px 4px;
}

span.item-name-price{
  padding: 0 10px 0 0;
  display:flex;
  flex-direction:column;
  justify-content:center;
  font-size: 16px;
  line-height: 22px;
  color: #333;
  font-weight: 500;
  font-family: 'Work Sans medium';
    .pro-name {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      font-weight: 500;
      font-family: 'Work Sans medium';
    }
  p{
    font-weight: normal;
    color: #777777;
    font-size: 13px;
    font-family: 'Work Sans medium';
  }
}
figure.imageDisplay span.item-name-price{
  padding: 0 10px 0 0;
}
figure.imageDisplay{
  display: flex;
  img.image{
    margin-right: 10px;
  }
}



span.i-status.active{
  //  background: #36D3C5;
  //  color: #fff;
  background: #828282;
  color: #FFFFFF;
  // padding: 4px 6px;
}
span.i-status.bad{
  background: #fa5c5d;
  color: #fff;
  
}
.mob-icon, .mob-footer{
  display: none;
}
.panel-right .ordered-items .order-process.step-two div.active:nth-child(1)::before{
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/pickup.svg');
  background-position:  center;
  background-repeat:  no-repeat;
  background-size: 20px 20px;
  background-color: #4F4F4F;
  position: absolute;
  content: '';
  top: -30px;
  border: 3px solid  #9a9a9a;

}
// .panel-right .ordered-items .order-process.step-two div .small-status p.small{
//   margin-top: 8px;
// }
.panel-right .ordered-items .order-process.step-two .ordered-i::before{
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/pickup.svg')!important;
  background-position:  center;
  background-repeat:  no-repeat;
  background-size: 20px 20px;
}

.panel-right .ordered-items .order-process.step-two div.active:nth-child(1){
  .i-status{
    background-color: #4F4F4F;
  }
}
.panel-right .ordered-items .order-process.step-two div.active:nth-child(2)::before{
  // background: #36D3C5 url('assets/images/icon2.png') center no-repeat !important;
  // background: #36D3C5 url('assets/images/Vector.png') center no-repeat !important;

}
.panel-right .ordered-items .order-process.step-two div.active:nth-child(3)::before{
  // background: #36D3C5 url('assets/images/icon3.png') center no-repeat !important; 
  background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/inactiveDelivery.svg');
  background-position:  center;
  background-repeat:  no-repeat !important; 
  background-color: #6FCF97;
  border-color:#B7E7CB;
}
.panel-right .ordered-items .order-process.step-two div.active:nth-child(4)::before{
  background: #36D3C5 url('https://ik.imagekit.io/2gwij97w0o/POE/inactiveDelivery.svg') 70% 70% center no-repeat;
  border-color:#84f9b5;
}
.panel-right .ordered-items .order-process.step-two div.return-initiated::before{
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/pickup.svg');
  background-position:  center;
  background-repeat:  no-repeat;
  background-size: 20px 20px;
  background-color: #4F4F4F;
  border: 3px solid  #9a9a9a;

}
.panel-right .ordered-items .order-process.step-two div.return-initiated.active::before{
  // background: #36D3C5 url('assets/images/i1.png') center no-repeat;
 background-color: #4F4F4F;
}

.panel-right .ordered-items .order-process.step-two div.return-initiated.active span.i-status{
  background-color: #4F4F4F;
}


.panel-right .ordered-items .order-process.step-two div.return-approved.active::before{
  // background: #36D3C5 url('assets/images/i2.png') center no-repeat !important;
}
.panel-right .ordered-items .order-process.step-two div.picked-up.active::before{
  // background: #36D3C5 url('assets/images/i3.png') center no-repeat !important;
  background-color:#ECB73F;
  // border:2px solid #F3DB71;

}
.panel-right .ordered-items .order-process.step-two div.picked-up.active{
       & > p{
  color:#ECB73F;
}
}
.panel-right .ordered-items .order-process.step-two div.picked-up::before{
  top: -30px!important;
}




.panel-right .ordered-items .order-process.step-two div.exchange.active::before{
  background: #36D3C5 url('assets/images/i4.png') center no-repeat;
}
.panel-right .ordered-items .order-process.step-two div.exchange.active::before{
  background: #36D3C5 url('assets/images/i4.png') center no-repeat;
}
.panel-right .ordered-items .order-process.step-two div.refund.active::before{
  background: #36D3C5 url('assets/images/i6.png') center no-repeat;
}


.panel-right .ordered-items .order-process.step-two div.order-cancelled-i{
  color: #EB5757;
}
.panel-right .ordered-items .order-process.step-two div.order-cancelled-i.active p{
color: #828282;
}
.panel-right .ordered-items .order-process.step-two div.order-cancelled-i::before{
  // background: #36D3C5 url('assets/images/order-cancel.png') center no-repeat !important;
    background-image: url('assets/images/order-cancel.svg');
    background-position:  center;
    background-repeat:  no-repeat !important;
    right: 0px;

    background-size: 20px 20px;
}

.panel-right .ordered-items .order-process.step-two div.order-cancelled-i.active::before{
  // background: #36D3C5 url('assets/images/order-cancel.png') center no-repeat !important;
  border-color:#f5abab;
   background-color: #EB5757;

}
.panel-right .ordered-items .order-process.step-two div.refund-raised::before{
  
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/pickup.svg');
  background-position:  center;
  background-repeat:  no-repeat;
  background-size: 20px 20px;
  background-color: #B7B7B7;
  border: 2px solid #F6F6F6;
}
.panel-right .ordered-items .order-process.step-two div.refund-raised.active::before{
  // background: #36D3C5 url('assets/images/refund-raised.png') center no-repeat !important;
  background-color: #4F4F4F;

}
.panel-right .ordered-items .order-process.step-two div.refund-initiated.active::before{
  // background: #36D3C5 url('assets/images/refund-initiated.png') center no-repeat !important;
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/inactiveDelivery.svg') center no-repeat !important;
  background-color:#6FCF97;
  border-color: #84f9b5;
}



.panel-right .ordered-items .order-process.step-two div.refund-succ::before{
  right: 0px;
  left: auto;
  // background: url('assets/images/refund-succ.png') center no-repeat !important;
}
.panel-right .ordered-items .order-process.step-two div > p.refund-succ.active{

  color:#6FCF97;

}
.panel-right .ordered-items .order-process.step-two div.refund-succ.active::before{
  // background: url('assets/images/refund-succ.png') center no-repeat !important;
}
.panel-right .ordered-items .order-process.step-two div.refund-bad.active::before{
  
background-color: #6FCF97;
}
.panel-right .ordered-items .order-process.step-two div > p.refund-bad.active{
  color:#828282;
}
.panel-right .ordered-items .order-process.step-two div.refund-bad{
  right: 30px;
 
}

.panel-right .ordered-items .order-process.step-two div.refund-bad::before{
  background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/inactiveDelivery.svg')!important;
  background-position:  center;
  background-repeat:  no-repeat;
  background-size: 18px 20px;
  top: -30px;
  right: 0px;
left: auto;
  background-color: #B7B7B7;
  border: 2px solid #F6F6F6;
}





.panel-right .ordered-items .order-process.step-two div.cancelled-i.active::before{

  background-color: #6FCF97;
  border-color: #84f9b5;
}


.mat-form-field-appearance-legacy .mat-form-field-ripple{
  display: none;
}
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option, .mat-option-text{
  font-weight: normal;
  font-style:normal;
  font-size: 11px;
  line-height: 14px;
  color: #4F4F4F;
  letter-spacing:0.02em;
  font-family: 'Work Sans Regular';
}
.mat-select-value{
  font-family: 'Lato';
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 1px;
}
.mat-select-panel{
  border-radius:8px!important;
}
.mat-select-panel .mat-option:last-child{
    border:none;
  
}
.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option{
  height: auto !important;
    padding: 12px 22px;
    border-bottom: 1px solid #B7B7B7;
   
}
.mat-select-panel:not([class*=mat-elevation-z]){
  box-shadow: 0px 0px 13px -8px rgba(0, 0, 0, 0.25);
}
.ng-trigger-transformPanel.mat-select-panel.mat-primary {
  
  min-width: 300px !important;
  float: right;
  margin-right: 2px;
  max-width: 340px;
  width: 100%;
}
.cdk-overlay-pane{
  max-width: 340px;
  width: 100%;
}
.return-mob-wrp, .cancel-order-wrp, .otp-mob-wrp{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-top:15px;
  padding-left: 25px;
  padding-right: 25px;
}
.otp-mob-wrp{
  height:100%;
}
.wrapper .otp-input:focus{
  border-color:  #828282;
}
.error-box{
  border-color:red!important;
}
.wrapper .otp-input{
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px !important;
  line-height: 19px;
  letter-spacing: 1px;
  color: #343434;
  margin-right: 21px!important;
  border-radius: 8px !important;
}
.mat-form-field-label mat-label{
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  // color: #4f4f4f;
  font-family: 'Work Sans Light';
  img{
    margin-left: 5px;
  }
}
.action-btn{
  display: flex;
}
.panel-right .ordered-items .order-process.step-two div.small.active span.small-status{
  color: #4f4f4f;
  
}
.panel-right .ordered-items .order-process.step-two div.small.active span.small-status{
  // color: #ECB73F;
  display: block;
  
}

.panel-right .product-detail-mob.ordered-items .item-list {
  display: none;
}

.d-flex{
  display: flex;
}
.otp-section.error-page,
.otp-section.success-page{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
}
.success-mob-wrp {
  display: flex;
  width: 100%;
  margin:auto;
}
.btn-primary {
  // background: #14b6ac;
  box-shadow: 0px 4px 8px rgba(105, 109, 109, 0.18), 0px 0px 2px rgba(75, 80, 80, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: inline-flex;
  padding: 9px 37px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.02em;

    font-family: 'Work Sans Light';
    font-style: normal;
    font-weight: 600;

border:none;
&:hover{
  background-color: #31bfb7;
}
}
.panel-right .ordered-items .order-process.step-two div.active br{
  display: none;
}
mat-form-field .mat-select-arrow {
  border-top-color: transparent;
  border-left-color: transparent;
}



.p_panel-left{
  background: #FFFFFF;
border: 1px solid #F6F6F6;
box-sizing: border-box;
box-shadow: 0px 0px 13px -8px rgba(0, 0, 0, 0.25);
font-family: $lato-font;


.purshase-details{
  margin-bottom: 15px;
  max-width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #828282;
  font-family: 'Work Sans medium';
}




}

.p_panel-left h2.heading{
  font-family: 'Work Sans medium';
  font-style: normal;
  font-size: 20px;
  line-height: 21px;
  color: #666;
  font-weight: normal;
  letter-spacing: 0;
  margin-top: 24px;

}
.p_panel-left .buyer-info h2.heading{
  font-size: 18px;

}


.p_panel-right{
background: #FFFFFF;

border: 1px solid #F6F6F6;
box-sizing: border-box;
box-shadow: 0px 0px 13px -8px rgba(0, 0, 0, 0.25);
  .ordered-items{
    .item-list{
      margin: 0px;
      .item-info{
        margin: 0px 0 0px;
        border-bottom: 1px solid #f6f6f6;
        padding: 24px 20px 22px 24px;
        width: 100%;
.item-name{
  margin: 0px;
  
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  position: relative;
  .order-heading{
    letter-spacing: 0px;
    font-family: 'Work Sans Medium';
    font-style: normal;
    font-size: 20px;
    color: #666;
    line-height: 19px;
    float: left;
  }
  a{
    font-family: 'Work Sans Medium';
    font-size: 16px;
    line-height: 100%;
    font-weight: normal;
    font-style: normal;
    color: #828282;
    float: right;
&:hover{
  color:#2DDBD1;
}
  }
}
      }
       }

       .order-process{
width: auto;
margin: 0px;
padding-top: 39px;
padding-left: 30px;
padding-right: 34px;
       }
  }

}



.center-alignment{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  font-family: 'Work Sans semibold';
  span{
    line-height: 0;
  }
}

.banner-title{
  display: flex;
  align-items: center !important;
  justify-content: center;
  padding: 6px 0px;
  margin-top: 12px;
  background: rgba(32, 159, 82, 0.05);
  border: 1px solid rgba(32, 159, 82, 0.2);
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 20px;
  margin-right: 20px;
  img{
    margin-right: 10px;
  }
  span{
    font-family: 'Work Sans medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.005em;
    color: #209F52;
  }
}

.see-update-box{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0, 0, 0, 0.3);
  z-index: 99999999999;
  display:none;
  pointer-events: auto;
}
.main-box{
  position: fixed;
  display:none;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999999999;
  width: 540px;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .header-box{
    // height: 94px;
    padding: 24px 24px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F6F6F6;
    h2{
font-weight: 600;
font-size: 18px;
line-height: 21px;
color:  #4F4F4F;
margin-bottom: 6px;
font-family: 'Work Sans Light';
font-weight:600;
letter-spacing:1px;
    }
    p{
      font-family: 'Work Sans Regular';
font-weight:normal;
font-style:normal;
font-size: 16px;
line-height: 18.7px;
letter-spacing: 0.02em;
color: #828282;
margin-bottom: 4px;
    }

    button{
      border: none;
      outline: none;
      background-color: transparent;
      height: 14px;
      width: 14px;
      background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;
    }
  }
  .bottom-box{

.new-update-design{
  height: auto;
  position: relative;
  &::before{
    content: "";
    border-left: 2px dashed #3EBD70;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 60px;
  }
  .each-update-box{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: 30px;
    transform: translateX(-22px);
    position: relative;
    .left-circle{
      height: 44px;
      width: 44px;
    
      margin-right: 25px;
      border-radius: 50%;
      background-color: #3EBD70;
      display: flex;
      box-shadow: 0px 12px 24px -6px rgba(62, 189, 112, 0.18), 0px 0px 1px rgba(62, 189, 112, 0.18);
      img{
        margin: auto; 
        display: block;
      }
      .order-placed-img{
        height: 22px;
        width: 22px;
       
      }
      .order-packed-img{
        width: 18px;
        height: 21px;
      }
      .order-picked-img{
        height: 24px;
        width: 24px;
      }
      .order-intransit-img{
        height: 24px;
        width: 24px;
      }
    }
    .right-content{
      .top-content-div{
display: flex;
&>div{
  white-space: nowrap;
  margin-right: 6px;
  font-family: 'Work Sans Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */
  padding-right: 10px;  
  letter-spacing: 0.02em;
  position: relative;
  color: #4F4F4F;
  &::after{
    // content: '';
    height: 3px;
    width: 3px;
    background: #4F4F4F;
    right: 0;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    transform: translateY(-50%);
  }
}
.updates{ 
  font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */
    background-color: transparent;
    letter-spacing: 0.01em;
    border: none;
    color: #FA5C5D;
    padding-right: 14px;
    background-image: url(/assets/images/down-red-arrow.svg);
    background-repeat: no-repeat;
    background-size: 8px 4px;
    background-position: center right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.uparrow-css{
      background-image: url(/assets/images/up-red-arrow.svg)!important;
    }
    }
      }
    }
    .bottom-content-div{
      .time-block{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        img{
          height: 14px;
          width: 14px;
          margin-right: 6px;
        }
        span{
          font-family: 'Work Sans Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 145%;
        /* or 20px */

        letter-spacing: 0.02em;

        color: #828282;

        }
      }
    }
    .detail-timeline{
      margin-top: 0px;
      .detail-timeline-box{
        &:last-child{
          margin-bottom: 0px;
        }
        margin-bottom: 16px;
        &>div{
      
          font-family: 'Work Sans Regular';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20.3px;
       
          letter-spacing: 0.02em;
          position: relative;
          color: #666666;
        
        }
        p{
          display: flex;
          align-items: center;
          justify-content: flex-start;
          img{
            height: 14px;
            width: 14px;
            margin-right: 6px;
          }
          span{
            font-family: 'Work Sans Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 145%;
          /* or 20px */

          letter-spacing: 0.02em;

          color: #828282;

          }
        }
      }
    }
  }
}






    max-height: 430px;
    box-sizing: border-box;
    margin-top: 0px;
  
    padding-top: 24px;
    padding-bottom: 25px;
    padding-left: 45px;    padding-right: 24px;

    margin-right: 0px;
    color: #4f4f4f;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 12px;

    }
    &::-webkit-scrollbar-thumb {
      background: #B7B7B7;
      border-radius: 50px;
      border: 2px solid #f6f6f6;
    }
    &::-webkit-scrollbar-track {
      background:#f6f6f6;
    }
    .timeline-box{
      margin-bottom: 36px;
      .date{

font-size: 14px;
line-height: 16px;
letter-spacing: 0.02em;
text-transform: uppercase;
margin-bottom: 17px;
font-family: 'Work Sans Light';
font-weight:600;
font-style:normal;
      }
      .time-action{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 14px;
        .time-box{
          font-size: 14px;
          width: 190px;
          line-height: 145%;
          letter-spacing: 0.02em;
          margin-right: 25px;
          font-family: 'Work Sans Light';
          font-style:normal;
          font-weight:normal;
        }
        .right-timeline{
       padding-right: 48px;
         flex: 1;
          .status-box{
          
            font-size: 14px;
            line-height: 20.3px;
font-style: normal;
letter-spacing: 0.02em;
font-family: 'Work Sans Light';
font-weight:600;
          }
          .location-box{
            font-size: 13px;
          font-family: 'Work Sans Light';
          font-style:normal;
          font-weight:normal;
          margin-top: 4px;
          line-height: 18px;

letter-spacing: 0.02em;

          }
         
        }
      }
    }
  }
}





.help-box{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0, 0, 0, 0.3);
  z-index: 99999999999;
  display: none;
}

.main-helpbox{
  position: fixed;
  display:none;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999999999999999;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
 
  z-index: 99999999999999;
  width: 450px;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .help-top-box{
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F6F6F6;

    h2{
   letter-spacing:1px;
      font-size: 16px;
      line-height: 19px;
      color: #4f4f4f;
      margin-bottom: 2px;
     font-family: 'Work Sans Light';
  
            }
           
      
            button{
              border: none;
              outline: none;
              background-color: transparent;
              height: 12px;
              width: 12px;
              background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg');
              background-repeat: no-repeat;
              background-position: center center;
              background-size: contain;
              cursor: pointer;
            }
  }
  .help-bottom-box{
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .text-block{
      margin-bottom: 30px;
      &:last-child{
        margin-bottom: 27px;
      }
      h1{
        
        font-size: 14px;
        line-height: 160%;
        color: #666;
        margin-bottom: 4px;
        font-family: 'Work Sans Light';
      }
      a{
        text-decoration: none;
       font-weight: 500;
font-size: 16px;
line-height: 145%;
letter-spacing: 0.02em;
color: #4f4f4f;
    font-family: 'Work Sans medium';
      }
    }
  }
}


.see-update-box .main-box .bottom-box .timeline-box .time-action .right-timeline .location-box:last-child{
  color: #C8C8C8;
  font-style: italic;
margin-top: 8px;

}

.close-track{
  display: block;
  width: 5px;
  height: 12px;
  background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/arrow-left.png');
  background-position: center;
  background-repeat: no-repeat;
  outline: none;
  border: none;
  background-color: transparent;
  background-size: contain;
  float: left;
    margin-top: 3.4px;
    margin-right: 10px;
  // position: absolute;
  // top: 50%;
  // left: -25px;
  // transform: translateY(-50%);
  cursor: pointer;
  }

app-footer{   
  header{
    
    padding-right: 15px;
    padding-left: 15px;
    max-width: 1324px;
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    text-transform: capitalize;
    a{
      padding: 0px;
      font-family: 'Work Sans Regular';
      font-weight:normal;
      font-style:normal;
    }
  }
}

.color-green{
  color:#828282!important;
}


.update-btn{
  color: #777777!important;
  font-size: 13px!important;
   font-family: 'Work Sans semibold';
  font-weight: 500!important;
  font-style:normal!important;
  text-decoration: underline;
  display: inline-block;
  position: relative;
  &::after{
    // content: '';
    // position: absolute;
    // height: 1px;
    // left: 0px;
    // right: 0px;
    // bottom: -2px;
    // background-color: #828282!important;
    display: none;
  }
}


.mat-form-field-infix{
  border: none;
  
  padding:12px 16px!important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px!important;
}

.mat-form-field-label-wrapper{
  top:0!important;
  left: 16px!important;
  padding:0px!important;
  line-height: 1!important;
  width: 90%;
}


.p-form-row{
  max-width:320px;
  width: 100%;
}

.p-full-width{
  width: 100%;
  position: relative;
  .input_field_blur{
    color: #b2b2b2; 
  }
  .text{
    text-transform: uppercase;

  }
}

.form-row-box{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.left-form-field{
  margin-right: 130px;
}
.p-custom-label{
  position: absolute;
  top: -29px;
  left: 0px;
  color: #828282;
  font-family: 'Work Sans Regular';
  font-size: 14px;
  line-height: 19px;
  font-weight: normal;
  letter-spacing:normal;
  font-style: normal;
}
.mb-30{
  margin-bottom: 35px;
}
.mb-12{
  margin-bottom: 20px;
}
.mr-18{
  margin-right: 18px;
}
.mat-form-field-wrapper{
  padding: 0px!important;
}
.mat-radio-outer-circle{
  border-width: 1px!important;
}


.address-line1{
  /* Pre Title */
font-family: 'Work Sans Regular';
  
font-style: normal;
font-weight: 600;


/* identical to box height */
margin-bottom: 9px;
letter-spacing: 0.02em;
text-transform: uppercase;
display: block;
/* Dark Gray */




  color: #333;
  font-size: 14px;
  line-height: 19px;
  
    

}

.address-line2{
  font-family: 'Work Sans Light';  
  font-style: normal;
  font-weight: 600;
font-size: 14px;
line-height: 145%;
/* identical to box height, or 15px */
display: block;
letter-spacing: 0.02em;

color: #4f4f4f;
}


.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label{
  opacity:0;
}
.exclusive-full-width{
width: 618px;
}


.panel-left .ordered-items .item-list:last-child{
  margin-bottom: 0px;
}


.mat-form-field-subscript-wrapper{
  overflow: visible!important;
}


.mat-error{
transform: translateY(8px);
font-family: 'Work Sans Regular';
font-style: normal;
font-weight: normal;
color:#EB5757;
letter-spacing: 0.02em;
}


.mat-select-value{
font-family: 'Work Sans Regular';
font-style: normal;
font-weight: normal;
font-size: 12px;
letter-spacing: 0.02em;
color: #4F4F4F!important;
}
.mat-form-field-hide-placeholder .mat-select-placeholder {
  font-family: 'Work Sans Regular';
  
font-style: normal;
font-weight: normal;
font-size: 12px;
letter-spacing: 0.02em;


color: #4F4F4F!important;
}
.mat-form-field {
  border:1px solid #e7e7e7;
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #4F4F4F!important;
}


.otp-input{
  height: 55px!important;
  width: 55px!important;
}



.otp-section{
  figure{
    img{
      height: 40px;
      width: 40px;
      object-fit: contain;
    }
  }
}


.editaddressBtn{
  text-align:left;
  padding-left:8px;
  font-family: 'Work Sans Light';
  font-weight:600;
  font-style:normal;
  margin-top:10px;
  font-size:12px;
}


.note-status{
  display: none;
  background: #F6F6F6;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 12px;
  margin-top:5px;
  border-radius: 4px;
}

input.mat-input-element{
  line-height: 19px;
  font-family: 'Work Sans Regular';
  font-weight: normal;
  font-style: normal;
  color: #4F4F4F;
  letter-spacing: 0.02em;
}

textarea{
  font-family: 'Work Sans Regular'!important;
  font-weight: normal!important;
  font-style: normal!important;
}

// .ng-valid.otp-input{
//   border-color: red!important;
// }


.cdk-overlay-container {
  z-index: 999999999;
}


.small{
  //transform: translateX(-15px);
}


.edit-address-popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0, 0, 0, 0.3);
  z-index: 99999999999;
  display:none;
 
}
.address-main-box{
  position: fixed;
  display:none;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999999999999999;
  width: 700px;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  .address-top-box{
display: flex;
justify-content: space-between;
padding-left: 34px;
padding-right: 34px;
padding-top: 20px;
padding-bottom: 20px;
border-bottom: 1px solid #E7E7E7;
.main-head{
font-family: 'Work Sans Light';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #828282;
}
button{
border: none;
outline: none;
background-color: transparent;
height: 14px;
width: 14px;
background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg');
background-repeat: no-repeat;
background-position: center center;
background-size: contain;
cursor: pointer;
}
  }
 
  .address-formbox{
    padding-top: 20px;
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 10px;
    max-height: 445px;
    overflow-y: auto;
    height: calc(100vh - 180px);
    &::-webkit-scrollbar {
      width: 6px;

    }
    &::-webkit-scrollbar-thumb {
      background: #B7B7B7;
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.block-row{
  width: 100%;
}
.custom-label-2{
  font-family: 'Work Sans Regular';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 160%;
display: block;
color: #666666;
margin-bottom: 8px;
}



.flex-parent-row{
justify-content: space-between!important;
}

.flex-one-row{
  // width: 45%;
  width: calc(50% - 16px);
}
.flex-two-row{
  width: 30%;
}
.flex-three-row{
  width: 100%;
}


.return-cancel{
  padding-top:12px!important;
  padding-bottom: 12px!important;
}

.otp-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.otp-span{
  height: 30px;
  width:auto;
  display: flex;
  img{
    height:30px;

    object-fit: contain;
    margin: auto;
  }
}


.btn-loader{
  display: flex;
  padding:0px!important;
  align-items: center;
  img{
    margin: auto;
    height: 25px;
    object-fit: contain;
  }
}


.error-input-class{
  border-color: #EB5757!important;
}

.valid-input-class{
  border-color: green!important;
}
.nostate-input-class{
  border-color: #c5c5c5!important;
}


.custom-transit{
  left: 45% !important;
  &::before{
    background-image:  url('https://ik.imagekit.io/2gwij97w0o/POE/inactivetransit.svg')!important;
  }
}
.support-email::after{
  display: none;
}


.poweredby{
  
  display: flex;
  // margin-bottom: 20px;
  // margin-right: 27px;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  right: 42px!important;
  bottom: 12px!important;
  z-index: 2147483!important; 
  // box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0 ,0 , 0.1), 0 1px 3px rgba(0, 0, 0 , 0.1);
  padding: 6px 8px 6px 6px!important;
  p{
    font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    // padding-top: 8px;
    // padding-bottom: 8px;
    // padding-right: 12px;
    // padding-left: 12px;
    color: black;
    // border: 1px solid #EFEFEF;
    // border-radius: 8px;
    display: flex;
    // background-color: black;
    a{
      display: flex;
      padding: 0px;
      margin-right: 6px;
      img{
        margin: auto;
        min-height: 14px!important;
        height: 14px!important;
        object-fit: contain;
        width: auto;
      }
    }
  }
 
}

.poweredby-mobile{
  display: none;
}


.input-error-css{
  border:1px solid red;
}


.btn-bottom-custom-1{
  padding-right: 24px !important;
align-items: flex-end;
border-top: 1px solid #efefef;
}



.error-pin{
  position: absolute;
  right: 9px;
  height: 14px;
  width: 14px;
  top: 50%;
  transform: translateY(-50%);
  object-fit: contain;
}


.cdk-overlay-pane{
  transform:translate(-15px,33px)!important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label{
  color:#EB5757,
}






.loader-css {
  margin: 0 auto;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top: 2px solid #fff;
  width: 25px;
  height:25px;
  -webkit-animation: spin 0.8s linear infinite; /* Safari */
  animation: spin 0.8s linear infinite;
}
.opt-loader-css{
  border-top-width: 2px;
  border-style: solid;
  
  // border-top: 2px solid #31bfb7;
  width: 35px;
  height:35px;
}
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



.size-class{
  background-color: #828282;
  color:#fff;
  font-size: 10px;
  // display: none!important;
  padding:2px 4px;
  border-radius: 2px;
  margin-left: 5px;
}

.size-radio-btn .radio-btn {
  margin-top: 10px;
}


.refundAlign{
  margin-top: 80px;
  align-self: flex-end;
  margin-right: 20px;
}


.brand-exclusive{
  display: flex;
  align-items: center;
  border: none;
  span{
    // border-bottom: 1px solid transparent !important; 

    &:hover{
      // border-bottom-color:#828282 !important; 
      text-decoration: underline;
    }
  }
  &:hover{
    border: none; 
  }
}
.link{
  width: 11px;
  min-height: 10px;
  margin-right: 8px;
  object-fit: contain;
}
header img.link{
  height: auto;
}
app-footer header a:hover{
  color: #828282 !important;
  // border-bottom: 1px solid #828282;
  text-decoration: underline;
}

.side-details{
 
  height: 142px;
  transform: translateX(-8px);
  position: relative;
  border: none !important ; 

}
.side-details:after{
  content: '';
  height: 1px;
  left: 0;
  right: 10px;
  top: -1px;
  position: absolute;
  background-color: #0000001a;
}


.secondary-info{
  background: #fff;
  border-top: 1px solid rgba(0, 0 ,0 , 0.1);
  margin-right: 2px;
    padding-top: 19px;
    padding-bottom: 14px;
  
    .purshase-details{
margin-bottom: 0px!important;
    }
}


.note-status-exclusive{
  padding:0px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  color: #808080;
  line-height: 20px;
  letter-spacing: 0.04em;
  max-width: 295px;
  font-weight: normal;
  img{
    margin-right: 8px;
  }
}


.SeeUpdatesShow{
  display:block;
}
.helpShow{
  display: block;
}
.cancelShow{
  display: block!important;
}
.editAddressShow{
  display: block;
}


.failed-delivery-wrp{
  padding-top: 24px;
}


.sidebar-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right:4px;
  &::-webkit-scrollbar {
    width: 12px;

    border-radius: 50px;
    background-color: #f6f6f6;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    width: 70%;
    padding:5px;
  }

  &::-webkit-scrollbar-thumb {
    background:#C8C8C8;
    border-radius: 50px;
    border: 3px solid #f6f6f6;
    width: 70%;
  }
}


.cancel-order-warning{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background:rgba(0, 0, 0, 0.3);
  z-index: 99999999999;
  display:none;

}
.main-cancel-box{

  position: fixed;
  display:none;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999999999999999;
  width: 600px;
  display: none;
  background: #FFFFFF;
  border: 1px solid #F6F6F6;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  .header-box{
    height: 59px;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F6F6F6;
    h2{
font-weight: 600;
font-size: 16px;
line-height: 19px;
color: #828282;
margin-bottom: 2px;
font-family: 'Work Sans Light';
font-weight:600;
letter-spacing:1px;

    }

    button{
      border: none;
      outline: none;
      background-color: transparent;
      height: 12px;
      width: 12px;
      background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/close-icon.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;
    }
  }
  .bottom-box{
    box-sizing: border-box;
    padding-top: 15px;
    padding-right: 25px;
    padding-left: 25px;

    line-height: 22px;
    letter-spacing: 0.5px;
    color: #828282;
    font-family: 'Work Sans Regular';
    font-style: normal;
   
   h2{
    position: relative;
    font-size: 14px;
    font-weight:600;
    color: #555;
    padding-left: 43px;
    background: #F6F6F6;
border-radius: 8px;
padding-top: 12px;
padding-bottom: 12px;
    &::after{
      content: '';
      position: absolute;
      left: 14px;
      top:17px;
      height: 16px;
      width: 16px;
      background-image: url('assets/images/warning-sign.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
   }
   p{
     font-size: 14px;
     margin-top: 12px;
   }
.block_new{
  display: grid;
    font-size: 12px;
    font-weight: lighter;
}
   .cancel-pro{
padding: 12px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-wrap: wrap;
border:1px solid #e7e7e7;
border-radius: 4px;
margin-top: 10px;
max-height: 306px;
overflow-y: auto;

&::-webkit-scrollbar {
width: 12px;

border-radius: 50px;
background-color: #f6f6f6;
}

&::-webkit-scrollbar-track {
background: transparent;
width: 70%;
padding:5px;
}

&::-webkit-scrollbar-thumb {
background:#C8C8C8;
border-radius: 50px;
border: 3px solid #f6f6f6;
width: 70%;
}

.prod{
margin-right: 16px;
padding-bottom: 12px;
width: 80px;
img{
width: 100%;
height: 94px;
  border-radius: 4px;
  object-fit: cover;
}
p{
  margin: 0px;
  width: 100%;
  overflow: hidden;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
}
}
   }
  }
  .btn-bottom {
    position: relative;
    background: $white;

    width: 100%;
    padding: 23px 34px;
    padding-left:24px;
    display: flex;
    justify-content: flex-end;
    .btn-continue {
      height: 32px;
      cursor: pointer;
      font-family: 'Work Sans semibold';
      font-weight: 500;
      width: 90px;
      border: none;
      outline: none;
      border-radius: 4px;
      line-height: 14px;
      text-transform: uppercase;
       font-style:normal;
      font-size: 12px;
      letter-spacing:1px;
      color: #fff;
      padding: 8px 0px;
      margin-left:16px;
      background: #2DDBD1;
      width: 90px;
      text-align: center;
      &:hover{
        box-shadow: 0px 4px 5px #e4e4e4;
      }
      &:disabled {
       
        pointer-events: none;
        background: #B7B7B7;
     
      }
   
    }
  
    .btn-cancel {
      cursor: pointer;
      border: 1px solid #828282;
      border-radius: 4px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 1px;
      color: #828282;
      padding: 8px 18px;
      text-transform: uppercase;
      height: 32px;
      background: none;
       font-family: 'Work Sans semibold';
      font-weight: 500;
      transition: all ease-in-out .2s;
      &:hover{
        background: #f9f9f9;
      }
    }
  }

  

}

.failed-del-btn-bottom{
  justify-content: flex-end!important;
}

.tooltip-exclusive{
  position: relative;
  &:hover{
    .tooltip{
    display: block;
    }
  }
   .tooltip{
    display: none;
    position: absolute;
    padding: 8px 5px;
    background: #4F4F4F;
border-radius: 2px;
font-family: 'Work Sans Light';
font-weight: 600;
color: #fff;
font-size: 12px;
left: 35%;
z-index: 99999999;
white-space: nowrap;
line-height: 16px;
top: 50%;
transform: translateY(-50%);
filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
&::after{

  content: '';
  position: absolute;
  height: 5px;
  width: 5px;
  background: #4F4F4F;
top: 45%;
left: -3px;
transform: rotateZ(45deg);
}
  }
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.registered-address{
  .mat-radio-label{
    align-items: flex-start!important;
  }

}


.additional-comments{
  margin-top: 12px;
  p{
    font-family: 'Work Sans Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #666666;
    margin-bottom: 8px;
  }
  .mat-form-field{
    width: 360px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 110px;

  }
  textarea.mat-input-element {
    height: 90px !important;
    width: 100%;
    resize: none;
}

}

.account-form{
  background: #FDFDFD;
border: 1px solid #F6F6F6;
box-sizing: border-box;
border-radius: 4px;
padding: 16px;
margin-bottom: 24px;
margin-left: 33px;
.mat-form-field{
  background-color: #fff;
}
}
.mb-20{
margin-bottom:20px;
}


.custom-label-new{
  color: #4F4F4F;
    font-size: 14px;
    white-space: initial;
    line-height: 22px;
    font-family: 'Work Sans Light';
    font-weight: 600;
    display: block;
    margin-bottom: 6px;
}


/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */

/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 44px!important;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
 position: absolute!important;
 height: 16px;
 width: 16px;
 right: 10px!important;
 top:50%!important;
 transform: translateY(-50%);
 background-image: url("/assets/images/close-toast.svg")!important;
 background-repeat: no-repeat;
 background-size: cover;
  /* opacity: 0.8; */
  span{
    display: none!important;
  }
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 1!important;
}
/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  display: none;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: auto !important;
  padding: 11px 40px 11px 50px!important;
  width: auto!important;
  align-items: center;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  font-family: 'Work Sans Light';
  font-weight: 400;
  display: inline-flex!important;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)!important;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
 box-shadow:none!important;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("/assets/images/toast-success-circle.svg")!important;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color:  #29B660!important;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
.oder-status-note1 img {
  position: relative;
  top: 1px;
}
.oder-status-note1 .center-alignment img{
  top:0px;
  float: left;
}
.p_panel-left .purshase-address{
  color: #4F4F4F;
  font-size: 13px;
}
.p_panel-right .ordered-items .item-list .item-info .item-name a img {
  margin: 0 12px 0 5px;
  position: relative;
  top: 1px;
}
.p_panel-right .ordered-items .item-list .item-info .item-name a:hover{
  color: #666 !important;
}
.icon-tooltip {
  position: relative; 
  margin-left: 12px;
}
.icon-tooltip span {
  background: #666;
    border-radius: 2px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: .5px;
    color: #fff;
    max-width: 270px;
    display: block;
    font-family: 'Work Sans Regular';
    padding: 10px;
    position: absolute;
    top: -50%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    text-align: left;
    width: 300px;
    -webkit-transform: translate(-50%,0%);
    transform: translate(-50%,0%);
}
.oder-status-note1 .icon-tooltip span{
  -webkit-transform: translate(-50.2%, -94%);
    transform: translate(-50.2%, -94%)
}
.icon-tooltip a:hover span{
  opacity: 1;
  visibility: visible;
}
figure.delivered-btn {
  display: flex;
  align-items: center;
  position: relative;
}
.icon-tooltip span:after {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #666;
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  margin: auto;
}
.icon-tooltip img {
  width: 16px;
}
.additional-comments .mat-form-field-label.mat-empty, 
.cancel-container .mat-form-field-label.mat-empty,
.cancel-container .mat-form-field-label mat-label{
  font-family: "Work Sans Regular";
  color: #999999 !important;
  font-size: 14px;
  top: 9px;
  overflow: visible;
  white-space: break-spaces;
  line-height: 145%;
}
.cancel-container .mat-form-field-label.mat-empty{
  top: 7px;
}
.cancel-container > div.form-row-box.flex-parent-row{
  width: 297px;
  .flex-one-row{
    width: 100%;
    mat-form-field{
      height: 38px;
      border-radius: 4px;
      span.mat-form-field-label-wrapper{
        height: 36px;
      }
      .mat-form-field-infix{
        padding: 9px 16px !important;
        input{
          font-size: 15px;
          line-height: 145%;
          letter-spacing: 0.02em;
          color: #4F4F4F;
        }
      }
    }
  }
}
.cancel-container > div.account-form{
  .form-row-box {
    mat-form-field{
      height: 38px;
    }
  }
}
.cancel-container .mat-form-field-infix{
  padding: 8px 16px !important;
}
.cancel-container .mat-form-field-infix .cdk-text-field-autofill-monitored:not(:-webkit-autofill),
.cancel-container .mat-select-value-text{
  font-size: 14px;
line-height: 145%;
letter-spacing: 0.02em;
color: #4F4F4F;
font-family: "Work Sans Regular";
}
.return-mob-wrp.scroll-content .left-form-field .mat-form-field-appearance-legacy .mat-form-field-label.mat-empty,
.return-mob-wrp.scroll-content .left-form-field .mat-form-field-appearance-legacy textarea{
  font-size: 14px;
  line-height: 145%;
  letter-spacing: 0.02em;
  color: #999999;
  font-family: "Work Sans Regular";
}
.return-mob-wrp.scroll-content .left-form-field .mat-form-field-appearance-legacy textarea{
  height: 108px;
  color: #4F4F4F;
}
.oder-status-note1 a{
  margin: 0 !important;
}
.center-alignment span span.checkbox{
  width: 20px;
  height: 20px;
}
.buyer-info .purshase-details{
  color: #4F4F4F;
}
.p_panel-left .buyer-info h2.heading{
  color: #828282;
}
.panel-left{
  padding-top: 6px;
  padding-bottom: 6px;
}
html body, html body.stop-scrolling{
  overflow: hidden !important;
}
app-root{
  height: 100vh;
    width: 100%;
    display: inline-block;
    overflow: auto;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
