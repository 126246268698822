* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@font-face {
    font-family: 'MicrosoftSansSerif';
    src: url('assets/fonts/MicrosoftSansSerif.eot?#iefix') format('embedded-opentype'),  url('assets/fonts/MicrosoftSansSerif.woff') format('woff'), url('assets/fonts/MicrosoftSansSerif.ttf')  format('truetype'), url('assets/fonts/MicrosoftSansSerif.svg#MicrosoftSansSerif') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Lato';
    src: url('assets/fonts/Lato-Regular.eot');
    src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), url('assets/fonts/Lato-Regular.woff2') format('woff2'), url('assets/fonts/Lato-Regular.woff') format('woff'),  url('assets/fonts/Lato-Regular.ttf') format('truetype'), url('assets/fonts/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('assets/fonts/Lato-Semibold.eot');
    src: url('assets/fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/Lato-Semibold.woff2') format('woff2'),  url('assets/fonts/Lato-Semibold.woff') format('woff'), url('assets/fonts/Lato-Semibold.ttf') format('truetype'), url('assets/fonts/Lato-Semibold.svg#Lato-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Lato';
    src: url('assets/fonts/Lato-Bold.eot');
    src: url('assets/fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), url('assets/fonts/Lato-Bold.woff2') format('woff2'),  url('assets/fonts/Lato-Bold.woff') format('woff'), url('assets/fonts/Lato-Bold.ttf') format('truetype'), url('assets/fonts/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


 @font-face {
    font-family: 'Work Sans Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Regular'), url('assets/fonts/worksansfont/WorkSans-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Thin'), url('assets/fonts/worksansfont/WorkSans-Thin.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans ExtraLight';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans ExtraLight'), url('assets/fonts/worksansfont/WorkSans-ExtraLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans Light';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Light'), url('assets/fonts/worksansfont/WorkSans-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Medium'), url('assets/fonts/worksansfont/WorkSans-Medium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans SemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans SemiBold'), url('assets/fonts/worksansfont/WorkSans-SemiBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Bold'), url('assets/fonts/worksansfont/WorkSans-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans ExtraBold';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans ExtraBold'), url('assets/fonts/worksansfont/WorkSans-ExtraBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Work Sans Black';
    font-style: normal;
    font-weight: normal;
    src: local('Work Sans Black'), url('assets/fonts/worksansfont/WorkSans-Black.woff') format('woff');
    }

$lato-font: 'Lato', sans-serif;
$montserrat-font: 'Montserrat', sans-serif;
$microsoft-font: 'MicrosoftSansSerif', sans-serif;

body {
    margin: 0;
    font-size: 14px;
    font-family: $lato-font;
    line-height: 1.2;
    font-weight: 400;
}

a {
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:focus {
        text-decoration: none;
        outline: none;
    }

    &:hover {
        text-decoration: none;
        outline: none;
    }

    &:active {
        text-decoration: none;
        outline: none;
    }
}

ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6, p, figure {
    margin: 0;
    padding: 0;
}

.text-right{
    text-align: right;
}

// color variables
$body-bg: #F6F6F6;
$white: #ffffff;
$black: #000000;
$darkGray:#828282;
$light-black: #333333;
$grey: #666666;
$light-grey: #999999;
$red: #ff0000 ;
$error-bg: #ffefef;
$status-orange: #dcae1e;
$green: #59b84d;
$green-success: #45af37;
$blue: #1190da;
$radio-btn-blue: #0095ff;
$form-border: #979797;
$shadow-color: #010101;
$light-orange: #f3f1c0;
$default-btn-color: #2d78ef;
$add-address-btn: #2873f0;
$defaull-btn-bg: #f9f9f9;
$defaull-btn-border: #dadada;
$tool-tip-border: #eeeeee;
$select-border: #d9d9d9;
$disable-btn-bg: #fafafa;
$disable-btn-color: #e0e0e0;
$disable-btn-border: #e0e0e0;
$footer-border: #ababab;
$notes-sticker-border: #e6e6e6;

$blue-accent:#2DDBD1;



// mixin
@mixin text-color($color, $opacity) {
    color: rgba($color, $opacity);
}

@mixin background-color($color, $opacity) {
    background: rgba($color, $opacity);
}

@mixin border-color($color, $opacity) {
    border: rgba($color, $opacity);
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin appearance($value){
    -webkit-appearance: $value;
    -moz-appearance: $value;
    -ms-appearance: $value;
    appearance: $value;
}

@mixin box-shadow($a, $b, $c, $d, $shadow-color, $shadow-opacity){
    -webkit-box-shadow: $a $b $c $d rgba($shadow-color, $shadow-opacity);
    -moz-box-shadow: $a $b $c $d rgba($shadow-color, $shadow-opacity);
    box-shadow: $a $b $c $d rgba($shadow-color, $shadow-opacity);
}

// Browser Prefixes
@mixin transform($transforms) {
	-webkit-transform: $transforms;
	-moz-transform: $transforms;
	-ms-transform: $transforms;
	transform: $transforms;
}

// Rotate
@mixin rotate ($deg) { 
	@include transform(rotate(#{$deg}deg));
}

// Scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// Translate
@mixin translate ($x, $y) {
	@include transform(translate($x, $y));
}

// TranslateY
@mixin translateY ($y) {
    @include transform(translateY($y));
}

// TranslateX
@mixin translateX ($x) {
    @include transform(translateX($x));
}

// Skew
@mixin skew ($x, $y) {
	@include transform(skew(#{$x}deg, #{$y}deg));
}

// Transform Origin
@mixin transform-origin ($origin) {
    	-webkit-transform-origin: $origin;
    	-moz-transform-origin: $origin;
	-ms-transform-origin: $origin;
	transform-origin: $origin;
}
