@media only screen and (min-width: 1360px) {
  .container{
    max-width: none;
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media only screen and (max-width: 1369px) {
  .item-name span{
    float: right;
  }
  header {
    margin: 8px 0;
  }
 
  .row {
    // max-height: 515px;
    min-height: 100%;
    position: relative;
    overflow: hidden;   
  }

  .panel-left {
    padding: 5px 0px 5px;
  }
  .p_panel-left{
    padding-top: 5px;
    padding-left: 23px;
  
  }
  .panel-right {
    padding: 25px 30px;

    // .scroll-content {
    //     width: calc(100% + 8px);
    // }

    .btn-bottom {
    
      @include border-radius(0 0 4px 4px);
    }
  }

.p_panel-right{
  padding: 0px;
}


  footer {
    // padding: 7px 15px;
    right: 36px;
  }
  .panel-left .ordered-items .item-name{
    max-width: 90%;
  }
}

@media only screen and (max-width: 1024px) {
  .panel-right {
    margin-left: 30px;
    width: calc(64.254577% - 30px);
  }

  .panel-left {
    // margin-bottom: 30px;
    .purshase-details {
      padding-right: 20px;
    }
  }
  // .panel-right .scroll-content{
  //   max-height: calc(100vh - 174px);
  //   padding-bottom: 60px;
  // }
  .refundAlign{
    padding-left: 56px; padding-top: 0px;
  }
  .panel-left .ordered-items .item-name{
    max-width: 85%;
  }
}

@media only screen and (max-width: 991px) {
  header{
    padding: 0px;
    margin: 0px!important;
  }
  header head{
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    img{
      margin: auto;
      min-height: 24px;
      // width: auto;
      height: 45px;
    }
  }
  .refundAlign{
    padding-left: 65px; padding-top: 0px;
    padding-right: 20px;
  }
  body{
    background-color: #F8F8F8;
  }
    .container {
    padding: 0px 10px;
  }
  .left-panel-show,
  .left-panel-hide {
    display: block;
  }
 
  footer{
    display: none;
  }
  .panel-left .ordered-items .item-name{
    // width: 100%;
    // white-space: initial;
  }
  .mob-footer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 26px;
    flex-flow: row wrap;
    justify-content: center;
    padding: 0px 16px;
    z-index: 999999999;
    background: rgb(246, 246, 246);
    p.second-para{
      margin: 4px 0;
    }
    p{
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin: 5px 0; 
      a{
      
        color: #828282;
        font-size: 13px;
        font-family: 'Work Sans Light';
        display: inline-flex;
        line-height: 20px;
        font-weight: 600;
        text-transform: capitalize;
        letter-spacing: 0.5px;
        width: 90px;
        justify-content: center;
        &:last-child{
          justify-content: flex-end;
        }
        &:first-child{
          justify-content: flex-start;
        }
      }
    }
  }

  .mob-footer .one-footer-link p {
    justify-content: center;
}

.mob-footer .one-footer-link p a:first-child {
    justify-content: center;
}
.second-para{
  justify-content: center!important;

  }

  .row {
    max-height: auto;
    min-height: auto !important;
    height: auto !important;
    margin-bottom: 0;
  }

  .panel-left {
    width: 100%;
    // height: calc(100vh - 240px) !important;
    height: auto !important;
    z-index: 9;
   max-height: none !important;
    padding:0 5px 75px 15px;
    padding-right: 5px;
        border-radius: 12px 12px 0px 0;
    // transition: opacity, left 0.2s;
    box-shadow: none;
    background: no-repeat;
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
    background: #fff;
    overflow: auto;
    h2.heading{
      justify-content: flex-start;
      color: #828282;
      position: relative;
      .mob-icon{
        display: block;
        position: absolute;
        left: 0;
      }
    }
  }
  
  .panel-left .ordered-items .item-list{
    background: #fff;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    margin-bottom: 8px;
  }


  
  

  .panel-right {
    width: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    left: -100%;
    // transition: all 0.5s;
    display: flex;
    flex-direction: column;
    z-index: 999999999;
    box-shadow: none;
    margin-bottom: 22px;
    border: 0;
    background: #f8f8f8;
  transition: all .3s ease-in;
    h3 {
      // position: absolute;
      // right:20px;
      font-size: 20px;
    }

    .scroll-content {
     
     
    }

    .ordered-items {
      display: flex;
      flex-direction: column;
      
    
      // overflow-y: auto;
      padding: 40px 20px 20px 20px;
      border-bottom: 1px solid rgba(231, 231, 231, 0.9);
      // border-top-left-radius: 40px;
      // border-top-right-radius: 40px;
      border-top-left-radius: 23px;
      border-top-right-radius: 23px;
      background: #fff;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: $body-bg;
      }

      &::-webkit-scrollbar-thumb {
        background: $light-grey;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $grey;
      }

      .order-process {
        
        display: block;
        width: auto;
        
      }
      .action-container {
        // margin: 30px 0;
        margin: 0;
        flex-wrap: wrap;

        .status-note {
          width: 80%;
          margin: 0 auto 30px;
          font-size: 17px;
          padding: 15px 20px;
          @include border-radius(7px);
          min-height: auto;
          &:before {
            content: "";
            width: 43px;
            height: 12px;
            left: inherit;
            right: -17px;
            top: -1px;
            @include rotate(45);
          }
        }

        .btn-container {
          width: 100%;
          margin: 0;
          display: flex;
          justify-content: space-between;
        }

        .default-btn {
          border: 1px solid $add-address-btn;
          @include border-radius(4px);
          width: 100%;
          max-width: 80%;
          justify-content: center;
          // max-width: 175px;
          padding: 9px;
          &.btn-confirm {
            padding-right: 9px;
            justify-content: flex-start;
          }

          & + .default-btn {
            border-top: 1px solid $add-address-btn;
          }
          &.cancelled-btn {
            border-color: $red;
          }
          &.corrective-btn {
            border-color: #59b84d;
          }
        }
      }
    }

    .btn-bottom {
      // width: calc(100% + 40px);
      // bottom: -20px;
      // margin-right: -20px;
      // margin-left: -20px;
      // position: absolute;
      // bottom: 0;
      width: 100%;
      padding:0px;
    }
  }
  .product-detail-mob {
    display: flex;
    width: 100%;
  }

  .item-name span{
  margin-right: -120px;    
  }

  // footer a {
  //   img{
  //     margin-right: 0;
  //   }
  //   span {
  //     display: none;
  //   }
  // }
  
  .panel-right .ordered-items .action-container .btn-container {
    flex-direction: column;
    align-items: center;
    .default-btn {
      min-height: 45px;
      &.btn-confirm {
        border-bottom: 1px solid #2873f0;
      }
    }
    .btn-container-2 {
      display: flex;
      max-width: 80%;
      width: 100%;
      margin-top: 10px;
      justify-content: flex-start;
      .default-btn {
        margin-right: 10px;
        max-width: 100%;
        &:last-child {
          margin-right: 0;
        }
        &.btn-confirm {
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .panel-right .ordered-items .order-process:before{
    // width: 1.6px;

    // top: 10px;
    left: 40px;
    top: 30px;
    border-top: none;
    height: calc(100% - 22px);
    position: absolute;
    border-left: 1px dashed #BBBBBB;
  }
  .panel-right .ordered-items .order-process.step-two div{
    position: relative;
    max-width: none !important;
    top: auto !important;
    left: auto !important;
    width: 100% !important;
    right: auto !important;
    padding-top: 0px !important;
    margin-bottom: 60px;
    padding-left: 32px;
  }
  .panel-right .ordered-items .order-process.step-two div.active{
    margin-bottom: 18px;
  }
  .panel-right .ordered-items .order-process.step-two div.return-initiated.active {
    margin-bottom: 32px !important;
}
  .panel-right .ordered-items .order-process.step-two div:last-child{
    margin-bottom: 0;    
  }
  .panel-right .ordered-items .order-process.step-two div.active:before,
  
    .order-process.step-two.step-four div.small.active:before{
    // width: 34px;
    // height: 34px;
    // left: -21px;
    // width: 42px;
    // height: 28px;
    // left: -21px;
    width: 30px;
    height: 30px;
    left: -12px;
  }
  span.oder-status-note{
    max-width: auto;
  }
  span.oder-status-note1{
    max-width: auto;
  }
  .panel-right .ordered-items .order-process.step-two.step-four div.small:before,
  .panel-right .ordered-items .order-process.step-two div:before{
   
    left: -12px;

  }
  .panel-right .ordered-items .order-process.step-two div:last-child::before{
    // left: -5px;
  }
  .panel-right .ordered-items .order-process.step-two div:last-child::after{
    z-index: -1;
    content: '';
    // background: white;
    width: 1.6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 10px;
    bottom: 0;
  }
  .left-panel-active > .mob-footer{
  display: none!important;
  }
  .left-panel-show {
    width: 100%;
    border-bottom: none;
    margin: 0;
    padding: 36px 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    // font-size: 20px;
    font-size: 24px;

    line-height: 24px;
    letter-spacing: 1px;
    // color: #333333;
    color: #2DDBD1;
    position: relative;
    display:none;
    figure{
      background: none;
      position: absolute;
      left: 20px;
    }
  }
  .panel-right .ordered-items .item-name a{
    font-size: 18px;
  }
  .panel-right .ordered-items .item-info{
    margin-left: 17px;
    max-width: 72%;
  }
  .panel-right .ordered-items .item-list{
    
  }
 .panel-right .return-item-pad h3{
    position: relative;
    right: auto;
    margin-top: 10px;
  }
  .return-mob-wrp, .cancel-order-wrp, .otp-mob-wrp, .success-mob-wrp {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 40px 16px 16px 20px;
    background: #fff;
    justify-content: initial;
  }
  .form-column{
    padding-right: 0;
  }
  .product-detail-mob .prod-info h4{
    font-size: 18px;
    font-weight: normal;
  font-family: 'Work Sans Light';
    color: #333333;
    max-width: 600px;
    font-style:normal;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    display: block;
  }
  .product-detail-mob .prod-info {
    max-width: 72%;
  }
  .product-detail-mob .prod-info p{
      font-family: 'Work Sans Light';
       font-style:normal;
  }
  .panel-right.mr-btm-0{
    margin-bottom: 0;
  }
  .panel-right .btn-bottom{
    // padding: 22px 24px;
  }
  .return-item-pad{
    padding: 0;
  }
  .account-holder-detail{
    width: 100%;
  }
  .account-holder-detail .text-right{
    margin-right: 0;
  }
  .product-detail-mob{
    // min-height: 750px;
    justify-content: initial !important;
  }
  .panel-right .product-detail-mob.ordered-items .item-list {
    display: block;
  }
  .panel-left .ordered-items .item-name{
    max-width: none;
  }
  .panel-right .ordered-items .order-process.step-two div:last-child:before{
    top: 9px !important;
  }
  .panel-right .ordered-items .order-process.step-two div:last-child span.i-status {
    top: 28px;
  }
  .panel-right .ordered-items .order-process.step-two  div.refund-succ:last-child:before,
   .panel-right .ordered-items .order-process.step-two div.refund-succ:last-child:before{
    top: -9px !important;
  }
  .panel-right .ordered-items .order-process.step-two.refund  div:last-child span.i-status,
  .panel-right .ordered-items .order-process.step-two div.refund-succ:last-child span.i-status {
    top: 24px;
  }
  .panel-right .scroll-content{
    padding-bottom: 100px;
  }
  .panel-left.p_panel-left.right-panel-active {
      max-height: 100vh !important;
  }
}

@media only screen and (max-width: 767px) {
.top-header header {
  display: none;
}
// .top-header section.topstirp {
//   display: none;
// }
  .panel-right .ordered-items .order-process.step-two div.refund-succ::before{
    right: auto;
    left: -12px;
    // background: url('assets/images/refund-succ.png') center no-repeat !important;
  }
  
  .row {

    max-height: none;
  }

  .form-column {
    flex: 0 0 100%;
    &.form-column-third {
      flex: 0 0 100%;
    }
  }

  .panel-right {
    min-height:none;
    &.otp-right-panel {
      &.otp-mobile {
        justify-content: start;

        h2 {
          margin-top: 10px;
        }

        .otp-section {
          text-align: left;

          p {
            // margin-bottom: 15px;
          }

          .form-group {
            // margin: 0;
          }

          figure {
            display: none;
          }

          h4 {
            display: block;
          }
        }

        .mobile-otp-btn {
          width: 100%;
          padding: 15px 30px;
          border-top: 1px solid $select-border;
          position: fixed;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: flex-end;
          background: #fff;
        }
      }
    }
    .scroll-content {
      width: 100%;
      max-height: none;
    }
    .cancle-order{
      padding: 0 0px;
      .mr-18 {
        margin-right: 0;
    }
    }
    .product-detail-mob .prod-img {
      padding-left: 0px;
    }
  }
  .panel-right .ordered-items .action-container .default-btn {
    margin: 0 auto;
    max-width: 80%;
    justify-content: center;
    &:last-child {
      margin-right: 0;
    }
  }
  .panel-right .ordered-items .order-process .tool-tip{
    min-width: 275px;
    max-width: 275px;
  }
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-second {
    transform: translateX(-70px);
  }
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-second:before,
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-second:after {
    left: 27%;
  }
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-third {
    transform: translateX(-132px);
  }
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-third:before,
  .panel-right .ordered-items .order-process .tool-tip.arrow-bottom.a-b-third:after {
    left: 51%;
  }
  .panel-right .ordered-items .action-container .status-note {
    margin-top: 15px;
  }

  .failed-delivery {
    .failed-delivery-content {
      max-width: 100%;
      padding-bottom: 40px;
    }
  }
  .otp-section{
    min-height: 300px;
  }
  .action-btn .cancelled-btn{
    padding: 9px 12px;
  }
}

@media only screen and (max-width: 991px) {
  .additional-comments .mat-form-field {
    max-width: 450px;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
  }
  .channelSlugText{
    text-align: center;
    padding: 20px 0px;
    font-size: 22px;
    color: #2ddbd1;

    
  }
  span.oder-status-note1 {
    max-width: 330px;
    width: 100%;
  }
  .mobile-store-link{
border-bottom:none;
position: relative;
border-bottom: 1px solid transparent;
padding-left: 18px;
&::after{
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background-image: url(assets/images/link-icon.svg);
  background-position: center;
background-repeat: no-repeat;
background-size: contain;
top: 2px;
// top: 50%;
// transform: translateY(-50%);
left: 0px;
}
&:hover{  
border-bottom: 1px solid #666;
}
  }

  .panel-right .ordered-items .order-process.step-two div{
    font-weight: 500;
  }


  .edit-address-popup{
.address-main-box{
  transform: translateY(100%);
}
  }


  .editAddressShow{
    .address-main-box{
        width: 100%;
        margin: initial;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        animation: movedown 0.3s ease-in-out forwards;
      }
  }
  .address-popup.show .address-main-box{
    animation: move 0.3s ease-in-out forwards;
  }




  .help-box .main-helpbox{
      transform: translateY(100%);
    }

  .main-helpbox{
      width: 100%;
      margin: initial;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      animation: movedown 0.3s ease-in-out forwards;
      // height: 300px;
      height: auto;
      top: auto;
      .help-bottom-box{
        padding-top: 20px;
        .text-block {
          margin-bottom: 15px;
        }
      }
    }
    .main-helpbox.helpShow{
      animation: move 0.3s ease-in-out forwards;
    }

  

  .cancel-order-warning{
    .main-box{
      transform: translateY(100%);
      .bottom-box{
        padding-right: 20px;
        padding-left: 35px;
        .cancel-pro{
          padding:12px 5px ;
          justify-content: space-around;
    max-height: 245px;
.prod{
  margin-right: 0px;
}
        }
      }
    }
  }
  .cancelShow{
    .main-box{
      width: 100%;
      margin: initial;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      animation: move 0.3s ease-in-out forwards;
    }
  }

  .see-update-box .main-update-box{
   transform: translateY(100%);
  }
  
  .main-update-box{
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    animation: movedown 0.3s ease-in-out forwards;
    .bottom-box{
      height: 345px;
    }
  }
  .main-update-box.SeeUpdatesShow{
    animation: move 0.3s ease-in-out forwards;
  }

  

  .edit-address-popup .address-main-box{
    transform: translateY(100%);
  }
  
    .address-main-box{
width: 100%;
position: fixed;
bottom: 0;
left: 0;
right: 0;
top: auto;
border-bottom-right-radius: 0px;
border-bottom-left-radius: 0px;
animation: move 0.3s ease-in-out forwards;
.address-top-box{
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.address-formbox{
  padding-top: 16px;
  padding-left: 16px;
  height: calc(100vh - 240px);
  max-height: initial;
  padding-right: 16px;
  padding-bottom:16px;
}
    }
  

    .cancel-order-warning{
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background:rgba(0, 0, 0, 0.3);
      z-index: 99999999999;
      display:none;
    
    }
    .main-cancel-box{
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      top: auto;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      animation: movedown 0.3s ease-in-out forwards;

      .header-box{
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
     
      .bottom-box{
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 16px;
        padding-bottom: 0px;

        .cancel-pro{
          max-height: 180px;
        }
       
      }
    
      .btn-bottom{
        padding-top: 16px;
        padding-bottom: 16px;
      }
      
    
    }

    .main-cancel-box.cancelShow{
      animation: move 0.3s ease-in-out forwards;
    }





@keyframes move {
  0%{
transform: translateY(100%);
  }
  100%{
transform: translateY(0%);
  }
}
@keyframes movedown {
  0%{
transform: translateY(0%);
  }
  100%{
transform: translateY(100%);
  }
}


  .side-details {
    transform: none;
    margin-bottom: 10px;
    height: auto;
  }

  .mobile-none{
display: none;
  }
  .sizes-full-width{
width:100%!important;
  }

  span.oder-status-note{
    font-size: 13px;
  }

  .special-field{
margin-bottom: 0px!important;
  }
  .mb-12{
    margin-bottom: 30px;
  }
  .address-formbox{
    .mb-12{
      margin-bottom: 20px!important;
    }
  }
  .poweredby-mobile{
    display: none;
    align-items: center;
    justify-content: center;
    // margin-bottom: 16px;
    // margin-top: 20px;
    // box-shadow: 0 0 0 1px rgba(0, 0 ,0 , 0.1), 0 1px 3px rgba(0, 0, 0 , 0.1);
    padding: 6px 8px 6px 6px!important;
    border-radius: 0px;
    position: fixed;
    right: 12px;
    bottom: 12px;
    z-index: 9999999999999999999999999;
    background-color: white;
    p{
      font-family: 'Work Sans Regular';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      color: #828282;
      display: flex;
      line-height: 14px;
      justify-content: center;
      // border-radius: 3px;
      // border: 1px solid #EFEFEF;
      // padding-top: 8px;
      // padding-bottom: 8px;
      // padding-left: 12px;
      // padding-right: 12px;
      justify-content: center;
      a{
        display: flex;
        margin-right: 8px;
        img{
        margin: auto;
          height: 12px;
          object-fit: contain;
          width: auto;
        }
      }
    }
   
  
  }
.poweredby{
  display: none;
}
  
  .panel-right .ordered-items .order-process.step-two div.active{
   
  }
  .small{
    transform: none;
  }

  .panel-right .ordered-items .order-process.step-two div{
    
  }

  .panel-right .ordered-items .order-process.step-two div:before {
    top:-9px!important;
    left:-12px!important
  }
  .panel-right .ordered-items .order-process.step-two div.active:before {
    top:-3px!important;
  }
  .panel-right .ordered-items .order-process.step-two div.refund-initiated.active:before {
    top:-7px!important;
  }
  div.active span.i-status{
    top: 29px;
  }
  .panel-right .ordered-items .order-process.step-two div.picked-up::before{
    top:-9px!important;
  }
  .panel-right .ordered-items .order-process.step-two div.picked-up.active::before{
    top:-2px!important;
  }  
  .panel-right .ordered-items div.active.order-cancelled-i:last-child span.i-status{
    top: 29px;
  }

// .ordered-items-cus{
//   height: 100%;

// }

  .panel-right form{
    height:100%;
  }
  .panel-right form {
    position: absolute;
    // left: -100% !important;
}
  .panel-right .btn-bottom{
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }


.footer-underline{
  border-bottom: 1px solid #e7e7e7;
  // padding-bottom: 12px;
}


.registered-address {
  // width: 98%;
}
.panel-right .scroll-content{
  // overflow-y: visible;
}
.otp-mobile {
  position: absolute;
  left: 0% !important;
  padding-bottom: 70px;
}
.panel-right .otp-mobile .scroll-content {
  overflow-y: visible;
  padding: 0px 0 0;
  margin: 0;
  width: 100%;
}
.panel-right .otp-mobile .ordered-items {
  width: 100%;
}
.otp-mobile form {
  left: -00% !important;
  padding: 0 15px;
  background: #fff
}

.panel-right
{
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 0px;
  position: absolute;
top:0;
    left: 100%!important;
    right: 0!important;
    
    z-index: 999999999;
    margin-bottom:0px;
    // padding-bottom: 60px;
    padding-bottom: 120px;
}
// .order-scroll{
//   padding-bottom: 50px;
// }
.left-panel-active {
  left: 0!important;
}


.btn-bottom-custom{
  margin-top: 20px;
  padding-bottom: 20px!important;
}
.panel-right .btn-bottom{
  padding-top: 16px;
  padding-bottom: 16px;
}

.mobile-row-one{
  width: 100%;
  margin-bottom: 20px;
}
.mobile-row-two{
  width: 48%;
}
.mobile-row-two.city_label, .mobile-row-two.state_label {
  width: 48%;
  float: left;
}
.mobile-row-two.state_label{
  float: right;
}
  .custom-label-2 {
    // font-size: 10px;
    margin-bottom: 4px;
  }
 
  .panel-right .ordered-items .order-process.step-two{
    // height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // max-height: 300px;
  }


  .p_panel-right .ordered-items .order-process {
    padding-top: 15px!important;
  }
  .panel-right .ordered-items .order-process.step-two div.active:nth-child(1)::before{
    top:0px;
  }


  .panel-right .ordered-items .order-process.step-two div:last-child{
    text-align: left;
  }
  .mt-20{
    margin-top: 30px;
  }
.form-row-box .p-form-row:first-child{
  margin-bottom: 20px!important;
}
.form-row-box .p-form-row:last-child{
  margin-bottom: 0px;
}

  .mobile-scroll-content{

  }
  .otp-section.success-page{
    min-height: 330px;
  }
.second-line{
  margin-top: 2.5px;
}
.panel-right  .scroll-content {
  flex: 1;
height: auto;
margin-bottom:0px;
padding-top:10px!important;
padding-left:15px!important;
padding-right:15px!important;

}
.box-shadowcss{
box-shadow: inset 0px 11px 8px -10px #05030326 , inset 0px -11px 8px -10px #05030326;
}
  .panel-right{
    min-height:none!important;
  }

  .return-mob-wrp{
    min-height: auto!important;
  }
  .mat-form-field-infix{
line-height: 19px;
  }

  .panel-right .btn-continue {
    margin-right: 0px;
  }
  .panel-right .btn-bottom {
    justify-content: space-between;
  }
  .container{
    padding: 0px;
  }
  .left-form-field{
    margin-right: 0px;
  }
  .form-row-box{
    flex-wrap: wrap;
  }
  .display-none{
    display: none;
  }
  .p-form-row{
    margin-bottom: 30px;
  }
.mobile-move{
  left: 0 !important;
}

.mobile-move1{
// position:relative;
}
.mobile-move{
  left: 0 !important;
}  
  .refundAlign {
   margin-top: 12px;
    align-self: flex-start; 
     margin-right: 0px;
  }

  span.i-status{
    left: -13px;
    top:23px;
    position: absolute;
  }
  .ordered-i{
    .i-status{
      top: 24px;
      left: -15px;
    }
  }

  .order-cancelled-i{
    .i-status{
      top: 24px;
    }
  }

.cancelled-i{
  p{
    transform:none;
  }
}


.row{
  min-height: 550px!important;
}
.custom-row{
  min-height: 700px!important;
  height: 100vh !important;
}
.panel-right .ordered-items .order-process.step-two div.order-cancelled-i.active::before{
  // background: #36D3C5 url('assets/images/order-cancel.png') center no-repeat !important;

   background-color: #EB5757;
top:0px;
}
.p_panel-right .ordered-items .item-list .item-info .item-name span a{
  display: none;
}


.close-track{
display: block;
width: 5px;
height: 12px;
background-image: url('https://ik.imagekit.io/2gwij97w0o/POE/arrow-left.png');
background-position: center;
background-repeat: no-repeat;
outline: none;
border: none;
background-color: transparent;
background-size: contain;
position: absolute;
top: 50%;
left: -22px;
transform: translateY(-50%);
margin: 0;
}

  .p_panel-right .ordered-items .item-list .item-info{
    border: none;
    height: auto;
    width: 100%;
    //border-bottom: 1px solid #f6f6f6;
    max-width: none;
    padding: 20px 15px;
    padding-left: 38px;
    // box-shadow: 2px 2px 2px #f9f9f9;
    // margin-bottom: 15px;
  }
  .banner-title {
    align-items: flex-start;
    margin: 0 15px 20px;
    padding: 10px 15px;
}

  .panel-right .ordered-items{
    padding: 0px!important;
    border: none;
    border-radius: 0px;
  }

  app-footer{
    display: none;
  }
  .mob-icon, .mob-footer{
    display: block;
    
  }
  .product-detail-mob {
    margin-bottom: 15px;
    background-color:#F6F6F6;
    padding: 7px 8px;
    border-radius: 4px 4px 0px 0px;
  }
  .product-detail-mob .prod-img img{
    height: 100%;
    width:58px;
    object-fit:cover;
  }
  .product-detail-mob .prod-info h4{
    margin-bottom: 8px;
  }
  .product-detail-mob .prod-info h4,.product-detail-mob .prod-info p{
    
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 145%;
letter-spacing: 0.02em;
color: #333;
  }
  .product-detail-mob .prod-info p{
    color: #777777;
    font-size: 13px;
  }
  .product-detail-mob .prod-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .panel-left {
    .heading {
      font-size: 17px;
      margin-bottom: 5px;
    }

    .purshase-details {
      margin-bottom: 24px;
    }

    .update-contact-list {
      margin-bottom: 40px;
    }
  }

  .panel-left > .mob-footer{
    display: none!important;
    }

  .action-container {
    .status-note {
      width: 90%;
    }
    .default-btn {
      width: 100%;
      max-width: 48%;
      padding: 9px 15px;
      background-position: calc(100% - 15px) center;

      &.need-help-btn,
      &.return-btn,
      &.cancelled-btn {
        background-position: calc(100% - 15px) center;
        &:hover {
          background-position: calc(100% - 15px) center;
        }
      }
    }
  }
  .size-radio-btn {
    display: flex;
    flex-wrap: wrap;
  }

  .size-radio-btn .radio-btn {
    margin-top: 10px;
  }
  .panel-right .ordered-items .order-process.step-two div {
    max-width: 80px;
    top: -40px;
  }
  .panel-right .ordered-items .order-process.step-two div:before {
    // top: 33px;
  }
  .product-detail-mob {
    margin-bottom: 30px;
  }
  .panel-right .ordered-items .order-process.step-two.step-four div.small .small-status {
    top: -15px;
    font-weight: 500;
  }
  .panel-right .ordered-items .order-process.step-two.step-four div.small .tool-tip.arrow-bottom {
    top: 37px;
  }
  .panel-left .ordered-items {
    height: auto;
    padding: 0;
    padding-right: 5px;
    transform: none;
  }
  .later-date li{
    margin-bottom: 10px;
  }
  .panel-right .btn-cancel{
    width: calc(50% - 10px);
    padding: 10px 0;
    height: 36px;
  }
  .panel-right .layout{
    width: calc(50% - 10px);
  }
  .panel-right .layout .btn-continue{
    width: 100%;
  }
  .panel-right .btn-continue,
  .panel-right .btn-bottom figure{
    width: calc(50% - 10px);    
    height: 36px;
  }
  .panel-right figure .btn-continue{
    width: 100%;
  }
  .flex-one-row{
    width: 48%;
margin-bottom: 0px!important;
  }
  .mobile_full_wd .flex-one-row{
    width: 100%;
  }
  .mobile_full_wd .flex-one-row:first-child{
    margin-bottom: 20px !important;
  }


  .wrapper .otp-input{
    margin-right: 22px!important;
  }
  .mobile-panel{
    overflow: visible;
  }
  .account-form{
    margin-left: 0;
    margin-bottom: 0;
  }
  .p-form-row{
    max-width: 400px;
  }
  .account-form .p-form-row{
    max-width: 100%;
  }
  .main-box .header-box{
    padding: 15px 15px;
  }
  .main-box .bottom-box {
    max-height: 70vh;
    // height: 90%; 
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 16px;
    padding-right: 0px;
    margin-right: 0px;
    height: auto;
}
.main-box .bottom-box .new-update-design:last-child .each-update-box:last-child{
  padding-bottom: 0;
}
.main-box .bottom-box .new-update-design .each-update-box .left-circle {
  height: 44px;
  width: 44px;
  margin-right: 13px;
}
.main-box .bottom-box .new-update-design .each-update-box .right-content .top-content-div > div {
  margin-right: 0;
}  
.poweredby-mobile {
  display: block;
  left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999999;
}  

.top-header .poweredby-mobile {
  // display: none;
} 
.right-content {
  width: calc(100% - 75px) !important;
}
.main-box .bottom-box .new-update-design .each-update-box{
  transform: none;
}
.panel-left .ordered-items .item-name{
  padding-right: 20px;
}
section.black-top-info{
  padding: 6px 15px;
}

// app-otp-confirmation .panel-right.otp-right-panel.otp-mobile{
//   padding-top: 20px;
// }
.panel-left .ordered-items{
  min-height: auto;
}
.buyer-info{
  padding-left: 0;
}
.p_panel-left .buyer-info h2.heading{
  margin-top: 16px;
}
.secondary-info .buyer-info{
  margin-left: 0 !important;
  width: 100%;
}
a.arrow-top.down-btn{
  border-radius: 4px 0 0 0 !important;
}
body{
  background-color: #fff !important;
}
header{
  background: rgb(246, 246, 246);
}

}


@media only screen and (max-width: 640px) {
  .panel-left{
    // height: auto !important;
  }
  .sidebar-container{
    height: max-content;
    // overflow-y: visible;
    // padding-bottom: 170px;
  }
  .panel-right .scroll-content {
    padding-bottom: 80px;
}
.btn-bottom.fix-bottom {
  bottom: 122px;
}
.panel-right h5{
  font-weight: 600;
}
// .panel-right .ordered-items .order-process:before{
//   left: 35px;
// }
header head{
  padding-top: 5px ;
  padding-bottom: 5px ;
}
.mob-footer{
  p{
    a{
      width: 33%;
    }
  }
  p.second-para{
    a{
      width: auto;
    }
  }
}

}

@media only screen and (max-width: 480px) {
  .refundAlign{
    padding-left: 62px;
    padding-right: 20px;
  }
  .registered-address{
    width: 100%;
    margin-left: 0;
  }
  .oder-status-note1 .icon-tooltip span {
    -webkit-transform: translate(-50.4%, -94%);
    transform: translate(-50.4%, -94%);
}
.panel-right .ordered-items .order-process:before{
  left: 35px;
}
}
@media only screen and (max-width: 380px) {
  .wrapper .otp-input{
    // margin-right: 10px!important;
  }
  // .otp-span {
  //   position: fixed;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   margin: auto;
  //   width: 30px;
  // }
  .otp-container .wrapper .otp-input {
    margin-right: 18px !important;
}
}



@media only screen and (max-width: 360px) {

  
  .panel-right {
    .ordered-items {
      .action-container {
        .default-btn {
          width: 100%;
          // max-width: 60%;
          padding: 9px 10px;
          background-position: calc(100% - 10px) center;

          &.need-help-btn,
          &.return-btn,
          &.cancelled-btn {
            background-position: calc(100% - 10px) center;
            &:hover {
              background-position: calc(100% - 10px) center;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 340px) {
  .panel-right .ordered-items .order-process.step-two div {
    // font-size: 11px;
  }
  .ng-trigger-transformPanel.mat-select-panel.mat-primary {
    min-width: 250px !important;
    margin-right: auto;
    float: none;
  }
}
