input:focus, select:focus{
  outline: none;
}

.form-group{
  margin-bottom: 20px;

  label{
      display: block;
  }
}

.form-control{
  padding-right: 10px;
  padding-left: 10px;
}

.login-main {

  .input-group {
    width: 100%;
    margin-bottom: 15px;
    .input-group-addon {
      padding: 0;
      background: none;
      width: 55px;
      height: 40px;
      border: 0;
      text-align: left;
      vertical-align: top;

      img {
        max-height: 38px;
      }
    }

    .form-control {
      height: 40px;
      font-size: 16px;
      @include border-radius(5px);

      &:last-child{
        @include border-radius(5px);
      }

      &:not(:first-child):not(:last-child){
        @include border-radius(5px);
      }
    }
  }

  .btn-login {
    color: $blue;
    padding: 10px 35px;
    float: right;
    font-size: 20px;
    margin-bottom: 30px;
  }

  .show-password {
    width: 42px;
    height: 30px;
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 99;
    cursor: pointer;
  }

  .error{
    color: $white;
  }
}

.btn{
  @include border-radius(20px);
  font-size: 12px;
}

.filters-left{
  select{
    float: left;
    border: 0;
    height: 30px;
    padding: 0 10px 0 0;
    border-bottom: 1px solid $grey;
      
  }

  .search-control{
      float: left;
      position: relative;
      max-width: 200px;

      &::before{
        position: absolute;
        top: 4px;
        left: 5px;
        content: '';
        width: 1px;
        height: calc(100% - 10px);
        background: $grey;
      }
  }

  input{
    width: 100%;
    border: 0;
    height: 30px;
    padding: 0 17px 0 15px;
    border-bottom: 1px solid $grey;
  }

  .search-icon{
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 5px;
    padding: 0;

    &::before{
      font-family: 'Glyphicons Halflings', sans-serif;
      content: '\e003';       
      font-size: 17px;
    }
  }
}

.filters-right{
  .action-control{
    select{
      min-width: 160px;
      height: 30px;
      vertical-align: middle;
      border-right: 0;
      padding: 0 8px;
      @include border-radius(5px 0 0 5px);
    }
    .btn{
      height: 30px;
      vertical-align: middle;
      @include border-radius(0 5px 5px 0);
      border-left: 0;
      padding: 0 15px;
  }
}
}

.advance-filter-form{
  label{
    font-size: 10px;
  }

  .form-control{
    font-size: 12px;
    height: 36px;
  }

  select{
    &.form-control{
      padding-left: 5px;
    }
  }
}

.btn-primary{
  &:disabled{
    cursor: default;
    @include background-color($blue, 0.6);
    @include border-color($blue, 0.6);
  }
}

.error{
  display: block;
  color: $red;
}

.form-group{
  label{
    font-size: 12px;
    .error{
      display: inline;
    }
    span, small{
        font-weight: 400;
    }
  }
}

.form-container{
  .btn{
      padding: 11px 26px;
      min-width: 110px;
      font-size: 14px;
      @include border-radius(4px);
  }
}

.group-radio-btn {
    margin-top: 5px;

    input {
        vertical-align: middle;
        margin-top: 0;
    }

    label {
        margin-left: 5px;
        font-size: 12px;
        margin-bottom: 0;
        vertical-align: middle;
        display: inline-block;
        font-weight: 400;
    }
}

.form-control{
  height: 44px;
  border-color: $form-border;
  color: $black;
}

textarea{
  &.form-control{
    max-width: 100%;
    min-height: 113px;
  }
}

select{
  &.form-control{
    padding-left: 5px;
  }
}

.group-btn{
  .btn{
    margin-top: 19px;
    margin-left: 15px;

    &:first-child{
      margin-left: 0;
    }
  }
}

.mat-radio-label{
  margin-bottom: 0;
}