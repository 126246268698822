.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td{
    padding: 13px 8px;
    font-size: 10px;
    @include text-color($black,0.80);
    vertical-align: middle;
}

.table-bordered {
    & > thead {
        & > tr {
            & > th, & > td {
                border-bottom-width: 1px;
            }
        }
    }
}

.table {
    & > thead {
        & > tr {
            & > th {
                border-bottom-width: 1px;

                .glyphicon-sort{
                    @include text-color($black,0.80);
                    margin-left: 5px;
                }
            }
        }
    }
}
